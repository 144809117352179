import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";
import React from "react";

export const PageSkeleton: React.FC = () => (
  <Stack w="100vw" maxW={"3xl"}>
    <Skeleton height="100px" />
    <Skeleton height="100px" />
    <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
    <Skeleton height="100px" />
    <SkeletonCircle size="20" />
    <SkeletonText mt="4" noOfLines={9} spacing="4" skeletonHeight="2" />
    <Skeleton height="100px" />
  </Stack>
);
