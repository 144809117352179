import React from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import ResponsiveFormStack from "../common/ResponsiveFormStack";
import {
  InputFormControl,
  PreviewDropdownFormControl,
} from "../common/FormControlComponents";
import { Hotel, Room } from "../../types/preview.types";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { PreviewDropdownItem } from "../common/PreviewDropdown";
import CurrencyContext from "../../context/CurrencyContext";
import { AccomodationRequest } from "../../types/accomodation.types";
import { formatPrice } from "../../utils/price-formatter";

interface AccomodationProps {
  accomodations: Hotel[];
  rooms: Room[];
  selectedAccomodation: AccomodationRequest;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  setFieldValue: Function;
  startDate: string;
  endDate: string;
}

const Accomodation: React.FC<AccomodationProps> = ({
  accomodations,
  rooms,
  selectedAccomodation,
  onChange,
  onBlur,
  setFieldValue,
  startDate,
  endDate,
}) => {
  const { i18n } = useLingui();
  const currency = React.useContext(CurrencyContext);
  const accomodationItems: PreviewDropdownItem[] = React.useMemo(() => {
    const items: PreviewDropdownItem[] = accomodations.map((accomodation) => ({
      id: accomodation.id,
      leftText: accomodation.name,
    }));
    items.unshift({
      id: 0,
      leftText: i18n._("Don't need hotel booking"),
    });
    return items;
  }, [accomodations, i18n]);

  const roomItems = React.useMemo(
    () =>
      rooms.map((room) => ({
        id: room.id,
        leftText: room.name,
        rightText: `${formatPrice(room.price)} ${currency}`,
      })),
    [currency, rooms]
  );

  return (
    <Stack>
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Accomodation</Trans>
        </Text>
      </HStack>
      <ResponsiveFormStack
        firstElement={
          <PreviewDropdownFormControl
            id={"hotel"}
            label={i18n._("Hotel")}
            values={accomodationItems}
            fieldName="hotelBooking.hotelId"
            setFieldValue={setFieldValue}
            selectedId={selectedAccomodation.hotelId}
            testId="hotel-booking-dropdown"
          />
        }
        secondElement={
          selectedAccomodation.hotelId ? (
            <PreviewDropdownFormControl
              id="room"
              label={i18n._("Room")}
              values={roomItems}
              fieldName="hotelBooking.roomId"
              setFieldValue={setFieldValue}
              isRequired
              selectedId={selectedAccomodation.roomId}
              defaultPreviewText={i18n._("Please select!")}
              testId="room-dropdown"
            />
          ) : (
            <></>
          )
        }
      />
      {selectedAccomodation.hotelId && (
        <>
          <ResponsiveFormStack
            firstElement={
              <InputFormControl
                id="arrivalDate"
                name="hotelBooking.arrivalDate"
                label={i18n._("Arrival")}
                inputType="date"
                isRequired
                value={selectedAccomodation.arrivalDate}
                onChange={onChange}
                min={startDate}
                max={endDate}
                testId="arrival-date-input"
              />
            }
            secondElement={
              <InputFormControl
                id="departureDate"
                name="hotelBooking.departureDate"
                label={i18n._("Departure")}
                inputType="date"
                isRequired
                value={selectedAccomodation.departureDate}
                onChange={onChange}
                min={startDate}
                max={endDate}
                testId="departure-date-input"
              />
            }
          />
          <ResponsiveFormStack
            firstElement={
              <InputFormControl
                id="noNights"
                name="hotelBooking.noNights"
                label={i18n._("Number of nights")}
                inputType="number"
                value={selectedAccomodation.noNights}
                readOnly
                onBlur={onBlur}
                min={1}
                isRequired
                testId="no-nights-input"
              />
            }
            secondElement={
              <InputFormControl
                id="roommate"
                name="hotelBooking.roomMate"
                label={i18n._("Name of roommate(s)")}
                inputType="text"
                value={selectedAccomodation.roomMate}
                onChange={onChange}
                testId="roommate-input"
              />
            }
          />
        </>
      )}
    </Stack>
  );
};

export default Accomodation;
