import React from "react";
import ResponsiveFormStack from "./common/ResponsiveFormStack";
import {
  InputFormControl,
  PreviewDropdownFormControl,
} from "./common/FormControlComponents";
import { useLingui } from "@lingui/react";
import { PresentationRequest } from "../types/presentation.types";
import { PreviewDropdownItem } from "./common/PreviewDropdown";

interface PresentationFormProps {
  presentation: PresentationRequest;
  types: PreviewDropdownItem[];
  topics: PreviewDropdownItem[];
  index: number;
  handleChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  setFieldValue: Function;
}

export const PresentationForm: React.FC<PresentationFormProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <div key={props.index} data-testid="presentation-form">
      <ResponsiveFormStack
        firstElement={
          <PreviewDropdownFormControl
            id={"typeOfPresentation"}
            label={i18n._("Presentation form")}
            values={props.types}
            fieldName={`presentations[${props.index}].presentationTypeId`}
            setFieldValue={props.setFieldValue}
            selectedId={props.presentation.presentationTypeId || undefined}
            defaultPreviewText={i18n._("Please select!")}
            isRequired
            testId="presentation-type-dropdown"
          />
        }
        secondElement={
          <PreviewDropdownFormControl
            id={"topicOfPresentation"}
            label={i18n._("Topic of presentation")}
            values={props.topics}
            fieldName={`presentations[${props.index}].presentationTopicId`}
            setFieldValue={props.setFieldValue}
            selectedId={props.presentation.presentationTopicId || undefined}
            defaultPreviewText={i18n._("Please select!")}
            isRequired
            testId="presentation-topic-dropdown"
          />
        }
      />
      <ResponsiveFormStack
        justOneElement={true}
        firstElement={
          <InputFormControl
            label={i18n._("Title of presentation")}
            inputType="text"
            name={`presentations[${props.index}].presentationTitle`}
            value={props.presentation.presentationTitle}
            onChange={props.handleChange}
            isRequired
            id="presentationTitle"
            testId="presentation-title-input"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="performers"
            label={i18n._("Name of author(s)")}
            inputType="text"
            name={`presentations[${props.index}].performers`}
            value={props.presentation.performers}
            onChange={props.handleChange}
            testId="performers-input"
          />
        }
        secondElement={
          <InputFormControl
            id="performersWorkplace"
            label={i18n._("Workplace of author(s)")}
            inputType="text"
            name={`presentations[${props.index}].performersWorkplace`}
            value={props.presentation.performersWorkplace}
            onChange={props.handleChange}
            testId="performers-workplace-input"
          />
        }
      />
    </div>
  );
};
