import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axios-query";
import {
  Participant,
  ParticipantFullSummary,
  ParticipantPreview,
} from "../../types/participant.types";
import { RegistrationRequest } from "../../types/registration.types";
import { PresentationSummary } from "../../types/presentation.types";

export const participantApi = createApi({
  reducerPath: "participantApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Participants", "Register"],
  endpoints: (builder) => ({
    listParticipantsByCongressId: builder.query<Participant[], number>({
      query: (congressId: number) => ({
        url: `participant/congress/${congressId}`,
      }),
      providesTags: ["Participants"],
    }),
    listParticipantPresentation: builder.query<PresentationSummary[], number>({
      query: (participantId: number) => ({
        url: `participant/${participantId}/presentation`,
      }),
    }),
    getParticipantById: builder.query<ParticipantPreview, number>({
      query: (congressId: number) => ({
        url: `participant/${congressId}`,
      }),
    }),
    getParticipantSummaryById: builder.query<ParticipantFullSummary, number>({
      query: (congressId: number) => ({
        url: `participant/${congressId}/preview`,
      }),
      providesTags: ["Register"],
    }),
    register: builder.mutation<ParticipantFullSummary, RegistrationRequest>({
      query: (registration: RegistrationRequest) => {
        const valuesByFieldId: any = {};
        registration.participantRegister.valuesByFieldId.forEach(
          (value: string | number, key: number) => {
            valuesByFieldId[key] = value;
          }
        );
        const mailingFields: any = {};
        registration.address.valuesByFieldId.forEach(
          (value: string | number, key: number) => {
            mailingFields[key] = value;
          }
        );
        const payload = {
          ...registration,
          participantRegister: {
            ...registration.participantRegister,
            valuesByFieldId,
          },
          address: {
            ...registration.address,
            valuesByFieldId: mailingFields,
          },
        };

        if (registration.payment) {
          const billingFields: any = {};
          registration.payment.valuesByFieldId.forEach(
            (value: string | number, key: number) => {
              billingFields[key] = value;
            }
          );
          payload.payment = {
            ...payload.payment,
            valuesByFieldId: billingFields,
          };
        }
        console.info("Registration payload", payload);
        return {
          url: "congress-event/register-to-event",
          data: payload,
          method: "POST",
        };
      },
      invalidatesTags: ["Register", "Participants"],
    }),
    downloadExcel: builder.mutation<Blob, number | string>({
      query: (congressId: number | string) => ({
        url: `excel-export/${congressId}`,
        method: "GET",
        headers: {
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }),
      invalidatesTags: ["Participants"],
    }),
    downloadExcelPresentations: builder.mutation<Blob, number | string>({
      query: (congressId: number | string) => ({
        url: `excel-export/presentations/${congressId}`,
        method: "GET",
        headers: {
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }),
      // invalidatesTags: ["Participants"],
    }),
    resendEmail: builder.mutation<void, number | string>({
      query: (participantId: number) => ({
        url: `participant/${participantId}/resend-email`,
        method: "GET",
      }),
      invalidatesTags: ["Participants"],
    }),
  }),
});

export const {
  useListParticipantsByCongressIdQuery,
  useRegisterMutation,
  useListParticipantPresentationQuery,
  useGetParticipantByIdQuery,
  useGetParticipantSummaryByIdQuery,
  useDownloadExcelMutation,
  useResendEmailMutation,
  useDownloadExcelPresentationsMutation,
} = participantApi;
