import React from "react";
import { participantApi } from "../../store/participant/participant";
import { PageSkeleton } from "../common/PageSkeleton";
import {
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { IoAnalyticsSharp } from "react-icons/io5";
import { TbMessages } from "react-icons/tb";
import { useLingui } from "@lingui/react";
import { Badge } from "../preview/Badge";
import { PresentationSummary as PresentationSummaryType } from "../../types/presentation.types";

interface FeatureProps {
  text: string;
  message: string;
  iconBg: string;
  icon?: React.ReactElement;
}

const Feature = ({ text, icon, iconBg, message }: FeatureProps) => {
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      align={{ base: "start", md: "center" }}
      w={"100%"}
    >
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
      <Text>{message}</Text>
    </Stack>
  );
};

interface PresentationSummaryProps {
  participantId: number;
  presentations?: PresentationSummaryType[] | null;
}

export const PresentationSummary: React.FC<PresentationSummaryProps> = ({
  participantId,
  presentations,
}) => {
  const { i18n } = useLingui();
  const [
    fetchPresentations,
    { data: presentationFetch = [], isLoading: arePresentationsLoading },
  ] = participantApi.endpoints.listParticipantPresentation.useLazyQuery();

  React.useEffect(() => {
    if (!presentations) {
      fetchPresentations(participantId);
    }
  }, [presentations, participantId, fetchPresentations]);

  const presentationList = React.useMemo(
    () => (presentations ? presentations : presentationFetch),
    [presentations, presentationFetch]
  );

  return arePresentationsLoading ? (
    <PageSkeleton />
  ) : (
    <Stack>
      {presentationList.map((presentation, index) => (
        <Stack key={index}>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={10}
            key={index}
            mb={8}
            minChildWidth="70%"
          >
            <Stack spacing={4}>
              <Badge color="blue">
                <Trans>Presentations</Trans>
              </Badge>
              <Heading as="h3" size="lg">
                {presentation.presentationTitle}
              </Heading>
              <Stack>
                <HStack>
                  <Text color="gray.500">
                    <Trans>Name of author(s):</Trans>
                  </Text>
                  <Text>{presentation.performers}</Text>
                </HStack>
                <HStack>
                  <Text color="gray.500">
                    <Trans>Workplace of author(s):</Trans>
                  </Text>
                  <Text>{presentation.performersWorkplace}</Text>
                </HStack>
              </Stack>
              <Stack spacing={4}>
                <Feature
                  icon={
                    <Icon
                      as={IoAnalyticsSharp}
                      color={"yellow.500"}
                      w={5}
                      h={5}
                    />
                  }
                  iconBg={"yellow.100"}
                  text={i18n._("Presentation form:")}
                  message={presentation.presentationType}
                />
                <Feature
                  icon={
                    <Icon as={TbMessages} color={"green.500"} w={5} h={5} />
                  }
                  iconBg={"green.100"}
                  text={i18n._("Topic of presentation:")}
                  message={presentation.presentationTopic}
                />
              </Stack>
            </Stack>
          </SimpleGrid>
          {presentationList.length > 1 &&
            presentationList.length - 1 !== index && <Divider mb={8} />}
        </Stack>
      ))}
      {presentationList.length === 0 && (
        <Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={10}
            mb={8}
            minChildWidth="70%"
          >
            <Stack spacing={4}>
              <Badge color="blue">
                <Trans>Presentation</Trans>
              </Badge>
              <Text>
                <Trans>I don't announce presentation(s)</Trans>
              </Text>
            </Stack>
          </SimpleGrid>
        </Stack>
      )}
    </Stack>
  );
};
