import React from "react";
import { useGetConferenceQuery } from "../../store/conference/conference";
import { accomodationApi } from "../../store/accomodation/accomodation";
import { Dispatch } from "@reduxjs/toolkit";
import { Location, NavigateFunction } from "react-router-dom";
import { Currency, Language, Status } from "../../types/conference.types";
import { isInsideIframe } from "../../utils/iframe-utils";
import { useRegisterMutation } from "../../store/participant/participant";
import { useFormik } from "formik";
import { ParticipantTitleType } from "../../types/participant.types";
import {
  Flex,
  Stack,
  Container,
  Divider,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import CurrencyContext from "../../context/CurrencyContext";
import { NotFoundType } from "../../pages/NotFound.page";
import { setParticipant } from "../../store/participant/participantSlice";
import { AddressType } from "../../types/address.types";
import { RegistrationRequest } from "../../types/registration.types";
import {
  getIntervalInDays,
  formatDateForInput,
} from "../../utils/date-formatter";
import Blur from "../common/Blur";
import { GreenButton } from "../common/CongressButton";
import { PageSkeleton } from "../common/PageSkeleton";
import AcceptAndSubmitFooter from "./AcceptAndSubmitFooter";
import Accomodation from "./Accomodation";
import { AdditionalText } from "./AdditionalText";
import ApplicationsData from "./ApplicationsData";
import AttendanceFees from "./AttendanceFees";
import BillingAddress from "./BillingAddress";
import { CustomFieldRegistration } from "./CustomFieldRegistration";
import EscortFees from "./EscortFees";
import MailingAddress from "./MailingAddress";
import Meals from "./Meals";
import Presentations from "./Presentations";
import Programs from "./Programs";
import CongressHeader from "../common/Heading";
import Comment from "./Comment";
import { CustomMailingRegistration } from "./CustomMailingRegistration";
import { useGetHeightAndSendMessage } from "../../utils/useGetHeightAndSendMessage";
import { getCorrectDate } from "../../utils/date-utils";

interface PreviewConTentProps {
  i18n: { activate: Function };
  congressId: number;
  dispatch: Dispatch;
  location: Location;
  navigate: NavigateFunction;
}

const PreviewContent: React.FC<PreviewConTentProps> = ({
  i18n,
  congressId,
  dispatch,
  location,
  navigate,
}) => {
  const pageRef = useGetHeightAndSendMessage();
  const {
    data: congressEvent = null,
    isLoading: isLoadingCongressEvent,
    isError,
  } = useGetConferenceQuery(congressId);
  const [
    fetchAccomodations,
    { data: accomodations = [], isLoading: isLoadingAccomodations },
  ] = accomodationApi.endpoints.listActiveAccomodations.useLazyQuery();
  const [fetchRooms, { data: rooms = [] }] =
    accomodationApi.endpoints.listActiveRoomsByAccomodationId.useLazyQuery();
  const [conditionsAccepted, setConditionsAccepted] =
    React.useState<boolean>(false);

  const language = React.useMemo(
    () => congressEvent?.language ?? Language.HUNGARIAN,
    [congressEvent]
  );

  const currency = React.useMemo(() => {
    return congressEvent?.currency === Currency.HUF
      ? congressEvent?.language === Language.HUNGARIAN
        ? "Ft"
        : "HUF"
      : "EUR" || "";
  }, [congressEvent]);

  const isPreviewPage = React.useMemo(
    () => location.pathname.includes("/congress"),
    [location.pathname]
  );
  const isIframe = React.useMemo(() => isInsideIframe(), []);

  const goBack = React.useCallback(() => {
    i18n.activate("hu");
    navigate(-1);
  }, [navigate, i18n]);

  const [
    register,
    {
      isLoading: isRegistering,
      isSuccess: isSuccessRegistration,
      data: registrationResult,
    },
  ] = useRegisterMutation();

  const formik = useFormik({
    initialValues: {
      participantRegister: {
        title: ParticipantTitleType.NONE,
        firstName: "",
        surName: "",
        workplace: "",
        email: "",
        valuesByFieldId: new Map<number, string | number>(),
      },
      address: {
        addressType: undefined as unknown as AddressType,
        city: "",
        street: "",
        postalCode: "",
        country:
          congressEvent?.language === Language.HUNGARIAN ? "Magyarország" : "",
        taxNumber: "",
        phone: "",
        valuesByFieldId: new Map<number, string | number>(),
      },
      payment: {
        payment: undefined,
        city: "",
        street: "",
        postalCode: "",
        country:
          congressEvent?.language === Language.HUNGARIAN ? "Magyarország" : "",
        taxNumber: "",
        email: "",
        phone: "",
        fullName: "",
        valuesByFieldId: new Map<number, string | number>(),
      },
      meals: congressEvent?.configJson.lunchEnabled ? [] : null,
      professionals: congressEvent?.configJson.activityEnabled ? [] : null,
      socials: congressEvent?.configJson.activityEnabled ? [] : null,
      hotelBooking: congressEvent?.configJson.accommodationEnabled
        ? {
            hotelId: 0,
            roomId: undefined,
            arrivalDate: formatDateForInput(
              getCorrectDate(
                congressEvent.startDate,
                congressEvent.bookingStartDate
              )
            ),
            departureDate: formatDateForInput(
              getCorrectDate(
                congressEvent.endDate,
                congressEvent.bookingEndDate,
                false
              )
            ),
            noNights: getIntervalInDays(
              getCorrectDate(
                congressEvent.startDate,
                congressEvent.bookingStartDate
              ),
              getCorrectDate(
                congressEvent.endDate,
                congressEvent.bookingEndDate,
                false
              )
            ),
            roomMate: "",
          }
        : null,
      presentations: congressEvent?.configJson.presentationEnabled ? [] : null,
      participantFee: congressEvent?.configJson.participantFeeEnabled
        ? { participationFeeId: undefined }
        : null,
      escortFee: congressEvent?.configJson.participantFeeEnabled
        ? {
            participationFeeId: undefined,
            numberOfAttendants: 0,
            attendantsName: "",
          }
        : null,
      congressEventId: congressId,
      comment: "",
    },
    onSubmit: (values: RegistrationRequest) => {
      console.debug("Registration submit values", values);
      register({
        ...values,
        hotelBooking:
          congressEvent?.configJson.accommodationEnabled &&
          values.hotelBooking?.hotelId
            ? values.hotelBooking
            : null,
        congressEventId: congressId,
      });
    },
    enableReinitialize: true,
  });

  const onCopy = React.useCallback(() => {
    const fullName = `${formik.values.participantRegister.firstName} ${formik.values.participantRegister.surName}`;
    formik.setFieldValue("payment.fullName", fullName);
    formik.setFieldValue("payment.country", formik.values.address.country);
    formik.setFieldValue(
      "payment.postalCode",
      formik.values.address.postalCode
    );
    formik.setFieldValue("payment.city", formik.values.address.city);
    formik.setFieldValue("payment.street", formik.values.address.street);
    formik.setFieldValue(
      "payment.email",
      formik.values.participantRegister.email
    );
    formik.setFieldValue("payment.phone", formik.values.address.phone);
  }, [formik]);

  React.useEffect(() => {
    if (congressEvent) {
      const language =
        congressEvent.language === Language.ENGLISH ? "en" : "hu";
      i18n.activate(language);
    }
  }, [i18n, congressEvent]);

  React.useEffect(() => {
    if (
      congressEvent &&
      congressEvent.configJson.accommodationEnabled &&
      congressId
    ) {
      fetchAccomodations(congressId);
    }
  }, [congressEvent, congressId, fetchAccomodations]);

  React.useEffect(() => {
    if (congressId) {
      if (
        formik.values.hotelBooking?.hotelId &&
        !isNaN(parseInt(formik.values.hotelBooking?.hotelId + ""))
      ) {
        fetchRooms({
          congressId,
          accomodationId: formik.values.hotelBooking.hotelId,
        });
      } else {
        formik.setFieldValue("hotelBooking.hotelId", 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [congressId, fetchRooms, formik.values.hotelBooking?.hotelId]);

  React.useEffect(() => {
    formik.setFieldValue(
      "hotelBooking.noNights",
      getIntervalInDays(
        formik.values.hotelBooking?.arrivalDate,
        formik.values.hotelBooking?.departureDate
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.hotelBooking?.arrivalDate,
    formik.values.hotelBooking?.departureDate,
  ]);

  React.useEffect(() => {
    if (isPreviewPage && congressEvent) {
      const language =
        congressEvent.language === Language.HUNGARIAN ? "hu" : "en";
      switch (congressEvent.status) {
        case Status.INACTIVE:
          navigate(`/notfound/${language}/${NotFoundType.Unavailable}`);
          break;
        case Status.FINISHED:
          navigate(`/notfound/${language}/${NotFoundType.Closed}`);
          break;
      }
    }
    if (isPreviewPage && isError) {
      navigate(`/notfound/hu/${NotFoundType.NotFound}`);
    }
  }, [congressEvent, isPreviewPage, navigate, isError]);

  React.useEffect(() => {
    if (isSuccessRegistration && registrationResult && registrationResult.id) {
      dispatch(setParticipant(registrationResult));
      navigate(`/success-registration/${registrationResult.id}`);
    }
  }, [dispatch, isSuccessRegistration, navigate, registrationResult]);

  return (
    <>
      <CurrencyContext.Provider value={currency}>
        <Flex
          minH={"100%"}
          align={"center"}
          bg={"transparent"}
          direction="column"
          my="12"
          ref={pageRef}
        >
          <Stack
            spacing={4}
            w={"full"}
            bg={"transparent"}
            rounded={"xl"}
            maxW={"4xl"}
            boxShadow={"lg"}
            p={6}
          >
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              {congressEvent && !isLoadingCongressEvent ? (
                <>
                  <Container centerContent>
                    <CongressHeader>{congressEvent.name}</CongressHeader>
                    <Text fontSize="xl" mt="4">
                      {formatDateForInput(congressEvent.startDate)} -{" "}
                      {formatDateForInput(congressEvent.endDate)}
                    </Text>
                  </Container>
                  <ApplicationsData
                    firstName={formik.values.participantRegister.firstName}
                    lastName={formik.values.participantRegister.surName}
                    title={formik.values.participantRegister.title}
                    workplace={formik.values.participantRegister.workplace}
                    email={formik.values.participantRegister.email}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                  />
                  {congressId && (
                    <CustomFieldRegistration
                      congressId={congressId}
                      fieldValues={
                        formik.values.participantRegister.valuesByFieldId
                      }
                      setFieldValue={formik.setFieldValue}
                    />
                  )}
                  {congressId &&
                    congressEvent.configJson.mailingDetailsEnabled && (
                      <>
                        <MailingAddress
                          addressType={formik.values.address.addressType}
                          city={formik.values.address.city}
                          street={formik.values.address.street}
                          postalCode={formik.values.address.postalCode}
                          country={formik.values.address.country}
                          phone={formik.values.address.phone}
                          handleChange={formik.handleChange}
                          setFieldValue={formik.setFieldValue}
                        />
                        {congressId && (
                          <CustomMailingRegistration
                            congressId={congressId}
                            fieldValues={formik.values.address.valuesByFieldId}
                            setFieldValue={formik.setFieldValue}
                          />
                        )}
                        <Divider my={{ base: 4 }} />
                      </>
                    )}
                  {congressId &&
                    congressEvent.configJson.paymentDetailsEnabled && (
                      <>
                        <BillingAddress
                          payment={formik.values.payment.payment}
                          onCopy={onCopy}
                          fullName={formik.values.payment.fullName}
                          city={formik.values.payment.city}
                          street={formik.values.payment.street}
                          tax={formik.values.payment.taxNumber}
                          phone={formik.values.payment.phone}
                          email={formik.values.payment.email}
                          country={formik.values.payment.country}
                          postalCode={formik.values.payment.postalCode}
                          handleChange={formik.handleChange}
                          setFieldValue={formik.setFieldValue}
                          fieldValues={formik.values.payment.valuesByFieldId}
                          congressId={congressId}
                          isCopyAvailable={
                            congressEvent.configJson.mailingDetailsEnabled
                          }
                        />
                        <Divider my={{ base: 4 }} />
                      </>
                    )}
                  {congressId &&
                    congressEvent.configJson.presentationEnabled && (
                      <>
                        <Presentations
                          congressId={congressId}
                          handleChange={formik.handleChange}
                          setFieldValue={formik.setFieldValue}
                          presentations={
                            formik.values.presentations || undefined
                          }
                        />
                        <Divider my={{ base: 4 }} />
                      </>
                    )}
                  {congressId &&
                    formik.values.participantFee &&
                    congressEvent.configJson.participantFeeEnabled && (
                      <>
                        <AttendanceFees
                          congressId={congressId}
                          handleChange={formik.handleChange}
                          setFieldValue={formik.setFieldValue}
                          fee={formik.values.participantFee}
                        />
                        <Divider my={{ base: 4 }} />
                      </>
                    )}
                  {congressId &&
                    formik.values.escortFee &&
                    congressEvent.configJson.participantFeeEnabled && (
                      <EscortFees
                        congressId={congressId}
                        handleChange={formik.handleChange}
                        setFieldValue={formik.setFieldValue}
                        fee={formik.values.escortFee}
                      />
                    )}
                  {congressEvent.configJson.accommodationEnabled &&
                    formik.values.hotelBooking &&
                    congressEvent.bookingStartDate &&
                    congressEvent.bookingEndDate && (
                      <>
                        {isLoadingAccomodations ? (
                          <Skeleton height="200px" />
                        ) : (
                          <Accomodation
                            accomodations={accomodations}
                            rooms={rooms}
                            selectedAccomodation={formik.values.hotelBooking}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            setFieldValue={formik.setFieldValue}
                            startDate={
                              formatDateForInput(
                                congressEvent.bookingStartDate
                              ) || ""
                            }
                            endDate={
                              formatDateForInput(
                                congressEvent.bookingEndDate
                              ) || ""
                            }
                          />
                        )}
                        <Divider my={{ base: 4 }} />
                      </>
                    )}
                  {congressId && congressEvent.configJson.lunchEnabled && (
                    <>
                      <Meals
                        congressId={congressId}
                        setFieldValue={formik.setFieldValue}
                        meals={formik.values.meals || []}
                      />
                      <Divider my={{ base: 4 }} />
                    </>
                  )}
                  {congressEvent.configJson.activityEnabled && (
                    <>
                      <Programs
                        congressId={congressId}
                        setFieldValue={formik.setFieldValue}
                        professionals={formik.values.professionals || []}
                        socials={formik.values.socials || []}
                      />
                      <Divider my={{ base: 4 }} />
                    </>
                  )}
                  {congressEvent.configJson.otherInfoEnabled && congressId && (
                    <AdditionalText congressId={congressId} />
                  )}
                  <Comment
                    handleChange={formik.handleChange}
                    value={formik.values.comment}
                  />
                  <Divider my={{ base: 4 }} />
                  <AcceptAndSubmitFooter
                    conditionsAccepted={conditionsAccepted}
                    onChange={setConditionsAccepted}
                    isLoading={isRegistering}
                    language={language}
                  />
                </>
              ) : (
                <PageSkeleton />
              )}
            </form>
          </Stack>
        </Flex>
        {!isPreviewPage && !isIframe && (
          <GreenButton
            onClick={goBack}
            style={{
              position: "fixed",
              top: 16,
              right: "10%",
            }}
            testId="preview-back-button"
          >
            <Trans>Back</Trans>
          </GreenButton>
        )}
        {!isIframe && (
          <>
            <Blur
              position={"sticky"}
              bottom={-50}
              left={"90%"}
              transform={"rotate(180deg)"}
              style={{ filter: "blur(70px)" }}
              zIndex={-1}
            />
            <Blur
              position={"sticky"}
              bottom={"100%"}
              left={"-20%"}
              marginLeft={"-32%"}
              marginTop={"-7%"}
              style={{ filter: "blur(70px)" }}
              zIndex={-1}
            />
          </>
        )}
      </CurrencyContext.Provider>
    </>
  );
};

export default React.memo(PreviewContent);
