import { Heading, Stack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ParticipantFeeSummary } from "../../types/fee.types";
import CurrencyContext from "../../context/CurrencyContext";
import { Trans } from "@lingui/macro";
import { CongressButtonScheme } from "../common/CongressButton";
import { formatPrice } from "../../utils/price-formatter";

interface FeeSummaryProps {
  feeSummary: ParticipantFeeSummary;
}

export const FeeSummary: React.FC<FeeSummaryProps> = ({ feeSummary }) => {
  const currency = useContext(CurrencyContext);
  return (
    <Stack
      px={0}
      py={3}
      justifyContent={{
        base: "flex-start",
        md: "space-between",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      alignItems={{ md: "center" }}
    >
      <Heading size={"md"} w={{ md: "30%" }}>
        <Trans>Attendance Fee</Trans>
      </Heading>
      <Heading
        size={"md"}
        bgGradient={CongressButtonScheme.GREEN_SCHEME}
        bgClip="text"
        textAlign="start"
        w={{ md: "37%" }}
      >
        {feeSummary.name}
      </Heading>
      <Heading
        textAlign={{ base: "left", md: "right" }}
        size={"xl"}
        w={{ md: "40%" }}
      >
        {formatPrice(feeSummary.totalPrice)} {currency}
      </Heading>
    </Stack>
  );
};
