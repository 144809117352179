import React from "react";
import { Button } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

export const enum CongressButtonScheme {
  PINK_SCHEME = "linear(to-r, red.400,pink.400)",
  GREEN_SCHEME = "linear(to-r, #1a9792,#24bcb4)",
}

interface CongressButtonProps {
  onClick?: Function | (() => {});
  type?: "button" | "submit" | "reset" | undefined;
  width?: string | number;
  children: React.ReactNode | React.ReactNode[];
  display?: any;
  mt?: string | number;
  ml?: string | number;
  disabled?: boolean;
  style?: React.CSSProperties;
  isLoading?: boolean;
  loadingText?: string;
  background: CongressButtonScheme;
  testId?: string;
}

const CongressButton: React.FC<CongressButtonProps> = ({
  onClick = () => {},
  type = "button",
  width,
  children,
  display,
  mt,
  ml,
  disabled = false,
  style = {},
  isLoading,
  loadingText = "",
  background,
  testId,
}) => {
  const clickCallback = React.useCallback(
    () => !disabled && onClick(),
    [onClick, disabled]
  );
  return (
    <Button
      fontFamily={"heading"}
      mt={mt}
      ml={ml}
      w={width}
      bgGradient={disabled ? "" : background}
      color={"white"}
      cursor={disabled ? "unset" : "pointer"}
      _hover={
        !disabled
          ? {
              bgGradient: background,
              boxShadow: "xl",
            }
          : {}
      }
      type={type}
      onClick={clickCallback}
      display={display}
      disabled={disabled}
      style={style}
      isLoading={isLoading}
      loadingText={loadingText}
      data-testid={testId}
    >
      {children}
    </Button>
  );
};

const CongressButtonMemo = React.memo(CongressButton);

const partiallyButtons =
  (partialProps: Partial<CongressButtonProps>) => (props: any) =>
    <CongressButtonMemo {...props} {...partialProps} />;

export const GreenButton = partiallyButtons({
  background: CongressButtonScheme.GREEN_SCHEME,
});
export const PinkButton = partiallyButtons({
  background: CongressButtonScheme.PINK_SCHEME,
});

interface CancelButtonProps {
  onClick: Function;
  testId?: string;
}

export const CancelButton: React.FC<CancelButtonProps> = ({
  onClick,
  testId,
}) => (
  <PinkButton width="fit-content" ml={2} onClick={onClick} data-testid={testId}>
    <FaTimes />
  </PinkButton>
);
