import React from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import {
  ChakraProvider,
  defineStyle,
  defineStyleConfig,
  extendTheme,
} from "@chakra-ui/react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { messages as enMessage } from "./locales/en/messages";
import { messages as huMessage } from "./locales/hu/messages";
import { isInsideIframe } from "./utils/iframe-utils";

i18n.load({ en: enMessage, hu: huMessage });
i18n.activate("hu");

const outline = defineStyle({
  borderColor: "gray.700",
  field: {
    borderColor: "gray.700",
  },
});

export const outlineTheme = defineStyleConfig({
  variants: { outline },
});

const iframeTheme = extendTheme({
  styles: {
    global: {
      html: {
        overflow: "hidden",
      },
      body: {
        bg: "whiteAlpha.300",
      },
      "*, *::before, &::after": {
        borderColor: "gray.500",
      },
      "*[class^='chakra-input'], *[class^='chakra-menu__menu-button'], *[class^='chakra-textarea']":
        {
          bg: "whiteAlpha.500 !important",
        },
    },
  },
});

const defaultTheme = extendTheme({});

function App() {
  React.useEffect(() => {
    console.debug(`Application version: ${process.env.REACT_APP_VERSION}`);
  }, []);
  const theme = React.useMemo(
    () => (isInsideIframe() ? iframeTheme : defaultTheme),
    []
  );
  return (
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <I18nProvider i18n={i18n}>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </I18nProvider>
      </ChakraProvider>
    </React.StrictMode>
  );
}

export default App;
