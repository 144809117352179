import { Box, Stack } from "@chakra-ui/react";
import React from "react";

interface ResponsiveFormStackProps {
  firstElement: React.ReactElement;
  secondElement?: React.ReactElement;
  mb?: string | number;
  justOneElement?: boolean;
  testId?: string;
}

const ResponsiveFormStack: React.FC<ResponsiveFormStackProps> = ({
  firstElement,
  secondElement = <></>,
  mb = "6px",
  justOneElement = false,
  testId,
}) => {
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      mb={mb}
      data-testid={testId}
    >
      <Box w="100%">{firstElement}</Box>
      {!justOneElement && <Box w="100%">{secondElement}</Box>}
    </Stack>
  );
};

interface DynamicFormStackProps {
  children: React.ReactElement[];
  testId?: string;
}

export const DynamicFormStack: React.FC<DynamicFormStackProps> = ({
  children,
  testId,
}) => {
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      mb="6px"
      wrap="wrap"
      data-testid={testId}
    >
      {children.map((child, index) => (
        <Box w={{ base: "100%", md: "calc(50% - 0.25rem)" }} key={index}>
          {child}
        </Box>
      ))}
    </Stack>
  );
};

export default ResponsiveFormStack;
