import { Navigate } from "react-router-dom";
import { getUserToken } from "../../utils/storage-handlers";
import React from "react";

interface ProtectedRouteProps {
  children: React.ReactElement | React.ReactElement;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (!getUserToken()) {
    return <Navigate to="/" replace />;
  }

  return children;
};
