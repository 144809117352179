import React from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import DataGrid, { HeaderColumn } from "../components/common/DataGrid";
import { AiFillEye, AiOutlineEdit } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import BasicPageLayout from "../components/layouts/BasicPage.layout";
import PageInstructions, {
  ActiveConferenceIcon,
  FinishedConferenceIcon,
  InactiveConferenceIcon,
} from "../components/common/PageInstructions";
import { useListConferencesQuery } from "../store/conference/conference";
import { Spinner } from "../components/common/Spinner";
import { useLingui } from "@lingui/react";
import { Status } from "../types/conference.types";

interface ActionButtonProps {
  viewRegistrationCallback: Function;
  editCongressCallback: Function;
  viewFormCallback: Function;
}

const ActionButtons: React.FC<ActionButtonProps> = ({
  viewRegistrationCallback,
  editCongressCallback,
  viewFormCallback,
}) => {
  const { i18n } = useLingui();
  return (
    <>
      <Tooltip label={i18n._("View Registrations")}>
        <IconButton
          variant="outline"
          colorScheme="pink"
          aria-label={i18n._("View Registrations")}
          fontSize="20px"
          icon={<FiUsers />}
          onClick={() => viewRegistrationCallback()}
          data-testid="view-registrations"
        />
      </Tooltip>
      <Tooltip label={i18n._("Edit Event")}>
        <IconButton
          variant="outline"
          colorScheme="facebook"
          aria-label={i18n._("Edit Event")}
          fontSize="20px"
          ml="8px"
          icon={<AiOutlineEdit />}
          onClick={() => editCongressCallback()}
          data-testid="edit-congress"
        />
      </Tooltip>
      <Tooltip label={i18n._("View Registration Form")}>
        <IconButton
          variant="outline"
          colorScheme="whatsapp"
          aria-label={i18n._("View Registration Form")}
          fontSize="20px"
          ml="8px"
          icon={<AiFillEye />}
          onClick={() => viewFormCallback()}
          data-testid="view-form"
        />
      </Tooltip>
    </>
  );
};

const ActionButtonsMemo = React.memo(ActionButtons);

const HomePage = () => {
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const { data: listOfConferences = [], isLoading } =
    useListConferencesQuery("");

  const onRegistrationClick = React.useCallback(
    (congressId: number) =>
      navigate(`/authenticated/registrations/${congressId}`),
    [navigate]
  );
  const onCongressEditClick = React.useCallback(
    (congressId: number) =>
      navigate(`/authenticated/congress/${congressId}/module-settings`),
    [navigate]
  );
  const onViewClick = React.useCallback(
    (congressId: number) => navigate(`/preview/${congressId}`),
    [navigate]
  );

  const conferences = React.useMemo(
    () =>
      listOfConferences.map((conference) => ({
        id: conference.id,
        name: (
          <>
            {conference.status === Status.ACTIVE && (
              <ActiveConferenceIcon
                w={6}
                h={6}
                style={{ marginBottom: -5, marginRight: 4 }}
              />
            )}
            {conference.status === Status.INACTIVE && (
              <InactiveConferenceIcon
                w={6}
                h={6}
                style={{ marginBottom: -5, marginRight: 4 }}
              />
            )}
            {conference.status === Status.FINISHED && (
              <FinishedConferenceIcon
                w={6}
                h={6}
                style={{ marginBottom: -5, marginRight: 4 }}
              />
            )}
            <>{conference.name}</>
          </>
        ),
        date: `${new Date(
          conference.startDate
        ).toLocaleDateString()} - ${new Date(
          conference.endDate
        ).toLocaleDateString()}`,
        actions: (
          <ActionButtonsMemo
            viewRegistrationCallback={() => onRegistrationClick(conference.id)}
            editCongressCallback={() => onCongressEditClick(conference.id)}
            viewFormCallback={() => onViewClick(conference.id)}
          />
        ),
        testId: conference.name,
      })),
    [listOfConferences, onCongressEditClick, onRegistrationClick, onViewClick]
  );

  const headerOfCongresses: HeaderColumn[] = React.useMemo(
    () => [
      {
        name: "id",
        displayName: "",
        hide: true,
      },
      {
        name: "name",
        displayName: i18n._("Name of Events"),
      },
      {
        name: "date",
        displayName: i18n._("Date"),
      },
      {
        name: "actions",
        displayName: i18n._("Actions"),
      },
    ],
    [i18n]
  );

  return (
    <BasicPageLayout pageTitle={i18n._("List of Events")}>
      <DataGrid columns={headerOfCongresses} data={conferences}></DataGrid>
      {isLoading && <Spinner />}
      <PageInstructions />
    </BasicPageLayout>
  );
};

export default HomePage;
