"use client";
import React from "react";
import {
  Box,
  Flex,
  Text,
  Stack,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GreenButton } from "../common/CongressButton";
import Logo from "../../static/logo.png";
import { removeUserToken } from "../../utils/storage-handlers";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href: string;
  testId?: string;
}

export default function WithSubnavigation() {
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const location = useLocation();

  const signOut = React.useCallback(() => {
    removeUserToken();
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    i18n.activate("hu");
  }, [i18n, location]);

  const goBack = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const isBackButton = React.useMemo(
    () => !location.pathname.includes("home"),
    [location]
  );

  return (
    <>
      <Box h={"60px"} position={"relative"}>
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          h={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
        >
          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
            >
              <Image
                src={Logo}
                alt="Congress & Hobby Service Logo"
                width="100px"
                objectFit="contain"
              />
            </Text>

            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>

          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            {isBackButton && (
              <GreenButton
                onClick={goBack}
                style={{
                  marginRight: "4px",
                }}
                testId="page-back-button"
              >
                <Trans>Back</Trans>
              </GreenButton>
            )}
            <GreenButton
              onClick={signOut}
              display={{ base: "none", md: "inline-flex" }}
              testId="sign-out-button"
            >
              <Trans>Sign out</Trans>
            </GreenButton>
          </Stack>
        </Flex>
      </Box>
      <Box position={"relative"} h={"calc(100vh - 60px)"} overflowY={"auto"}>
        <Flex py={{ base: 2 }} px={{ base: 4 }} align={"center"}>
          <Outlet />
        </Flex>
      </Box>
    </>
  );
}

const DesktopNav = () => {
  const { i18n } = useLingui();
  const navItems: Array<NavItem> = React.useMemo(
    () => [
      {
        label: i18n._("Events"),
        href: "/authenticated/home",
        testId: "all-events-page",
      },
      {
        label: i18n._("Create new Event"),
        href: "/authenticated/congress/new/module-settings",
        testId: "create-new-event-page",
      },
    ],
    [i18n]
  );
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link to={navItem.href ?? "#"}>
                <Box
                  p={2}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  data-testid={navItem.testId}
                >
                  {navItem.label}
                </Box>
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link to={href}>
      <Box
        role={"group"}
        display={"block"}
        p={2}
        rounded={"md"}
        _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
      >
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Text
              transition={"all .3s ease"}
              _groupHover={{ color: "pink.400" }}
              fontWeight={500}
            >
              {label}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
          <Flex
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"center"}
            flex={1}
          >
            <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Box>
    </Link>
  );
};
