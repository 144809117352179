import React from "react";
import {
  Checkbox,
  Container,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { GreenButton } from "../common/CongressButton";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Language } from "../../types/conference.types";

interface AcceptAndSubmitFooterProps {
  conditionsAccepted: boolean;
  onChange: Function;
  isLoading: Boolean;
  language: Language;
}

const AcceptAndSubmitFooter: React.FC<AcceptAndSubmitFooterProps> = ({
  conditionsAccepted,
  onChange,
  isLoading,
  language,
}) => {
  const { i18n } = useLingui();
  return (
    <Stack>
      <HStack mb="2">
        <Container centerContent>
          <Checkbox
            colorScheme="pink"
            size="lg"
            alignItems="flex-start"
            spacing="1rem"
            isChecked={conditionsAccepted}
            onChange={(event) => onChange(event.target.checked)}
            data-testid="accept-conditions"
          >
            <Text fontSize="s" mb="2" mt="-1.5">
              <Trans>
                I read and accept the{" "}
                <Link
                  href={
                    language === Language.HUNGARIAN
                      ? "https://congress-service.hu/ASZF/ASZF_h.pdf"
                      : "https://www.congress-service.hu/ASZF/ASZF_EN.pdf"
                  }
                  isExternal
                  color="teal.500"
                  style={{ textDecoration: "underline" }}
                >
                  Terms and Conditions
                </Link>
                ,{" "}
                <Link
                  href={
                    language === Language.HUNGARIAN
                      ? "https://congress-service.hu/ASZF/Adatkezelesi_tajekoztato.pdf"
                      : "https://www.congress-service.hu/ASZF/Information_on_Data_Handling.pdf"
                  }
                  isExternal
                  color="teal.500"
                  style={{ textDecoration: "underline" }}
                >
                  Privacy Policy
                </Link>{" "}
                and conditions what were published on the page of conference!
              </Trans>
            </Text>
          </Checkbox>
        </Container>
      </HStack>
      <HStack>
        <Container centerContent>
          <GreenButton
            disabled={!conditionsAccepted}
            type="submit"
            isLoading={isLoading}
            loadingText={i18n._("Submitting..")}
            testId="submit-registration"
          >
            <Trans>Submit</Trans>
          </GreenButton>
        </Container>
      </HStack>
    </Stack>
  );
};

export default AcceptAndSubmitFooter;
