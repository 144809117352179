export interface AdditionalText {
  id: number;
  congressEventId: number;
  text: string;
}

export enum FormType {
  BASE_DATA = "BASE_DATA",
  PAYMENT = "PAYMENT",
  MAILING = "MAILING",
}

export enum FieldType {
  NUMBER = "NUMBER",
  TEXT = "TEXT",
}

export interface FormField {
  id: number;
  congressEventId: number;
  fieldName: string;
  fieldType: FieldType;
  isRequired: boolean;
  enabled: boolean;
  ordinal: number;
  formType: FormType;
}

export const createEmptyFormField = (
  congressEventId: number,
  formType: FormType
): FormField => ({
  id: 0,
  fieldName: "",
  fieldType: FieldType.TEXT,
  isRequired: false,
  enabled: true,
  ordinal: 1,
  formType,
  congressEventId,
});
