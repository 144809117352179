import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import React from "react";

export interface PreviewDropdownItem {
  id: number | string | undefined;
  leftText: string | number | React.ReactElement | React.ReactElement[];
  rightText?: string | number | React.ReactElement | React.ReactElement[];
  style?: any;
}

export interface PreviewDropdownProps {
  values: PreviewDropdownItem[];
  selectedId?: number | string;
  fieldName: string;
  setFieldValue: Function;
  defaultPreviewText?: string;
  testId?: string;
}

const PreviewItem: React.FC<PreviewDropdownItem> = ({
  id,
  leftText,
  rightText,
  style,
}) => (
  <HStack key={id} justify={"space-between"} w={"100%"} style={style}>
    <Box textAlign={"left"}>{leftText}</Box>
    {rightText && (
      <>
        <Box w={20}></Box>
        <Box minW={"fit-content"}>{rightText}</Box>
      </>
    )}
  </HStack>
);

const PreviewItemMemo = React.memo(PreviewItem);

interface PreviewDropdownItemProps extends PreviewDropdownItem {
  onClick: Function;
}

const PreviewDropdownItemComponent: React.FC<PreviewDropdownItemProps> = ({
  id,
  leftText,
  rightText,
  onClick,
}) => (
  <MenuItem onClick={() => onClick(id)} key={id}>
    <PreviewItemMemo id={id} leftText={leftText} rightText={rightText} />
  </MenuItem>
);

const PreviewDropdownItemMemo = React.memo(PreviewDropdownItemComponent);

const PreviewDropdown: React.FC<PreviewDropdownProps> = ({
  values,
  selectedId,
  fieldName,
  setFieldValue,
  defaultPreviewText,
  testId,
}) => {
  const selectedItem = React.useMemo(() => {
    const selectedItem = values.find((item) => item.id === selectedId);
    return selectedItem ? (
      <PreviewItemMemo
        id={selectedItem.id}
        leftText={selectedItem.leftText}
        rightText={selectedItem.rightText}
        style={{
          height: 40,
          overflowY: "hidden",
          alignItems: "flex-start",
          paddingTop: "var(--chakra-space-2)",
          paddingBottom: "var(--chakra-space-2)",
        }}
      />
    ) : (
      <Input name={fieldName} value={undefined} border={"none"} />
    );
  }, [fieldName, selectedId, values]);

  const preview = React.useMemo(() => {
    return defaultPreviewText ? (
      <>
        <PreviewItemMemo
          id={"preview" + defaultPreviewText}
          leftText={defaultPreviewText}
          style={{
            height: 40,
            overflowY: "hidden",
            alignItems: "flex-start",
            paddingTop: "var(--chakra-space-2)",
            paddingBottom: "var(--chakra-space-2)",
          }}
        />
        <Select
          value={undefined}
          name={fieldName}
          rootProps={{
            style: {
              position: "absolute",
              left: 0,
            },
          }}
          icon={<></>}
        >
          <option value={undefined} />
        </Select>
      </>
    ) : (
      <Input name={fieldName} value={undefined} border={"none"} />
    );
  }, [defaultPreviewText, fieldName]);

  const onChange = React.useCallback(
    (id: number) => {
      setFieldValue(fieldName, id);
    },
    [setFieldValue, fieldName]
  );

  return (
    <Menu>
      <MenuButton
        px={4}
        py={2}
        h={"40px"}
        borderRadius="md"
        borderWidth="1px"
        w={"100%"}
        _hover={{ borderColor: "gray.300" }}
        _active={{ boxShadow: "outline" }}
        type="button"
        data-testid={testId}
      >
        <HStack justify={"space-between"}>
          {selectedId !== undefined ? selectedItem : preview}
          <Box>
            <ChevronDownIcon />
          </Box>
        </HStack>
      </MenuButton>
      <MenuList>
        {values.map((item, index) => (
          <PreviewDropdownItemMemo
            onClick={onChange}
            id={item.id}
            leftText={item.leftText}
            rightText={item.rightText}
            key={index}
          />
        ))}
      </MenuList>
    </Menu>
  );
};

export default React.memo(PreviewDropdown);
