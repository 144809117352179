import React from "react";
import { Badge, HStack, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import DataGrid, { HeaderColumn } from "../components/common/DataGrid";
import {
  AiFillEye,
  AiOutlineInfo,
  AiOutlineFundProjectionScreen,
} from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import BasicPageLayout from "../components/layouts/BasicPage.layout";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  useListParticipantsByCongressIdQuery,
  useResendEmailMutation,
} from "../store/participant/participant";
import { mapParticipantTitle } from "../utils/enum-formatter";
import {
  ExcelDownloadType,
  KeepPresentationType,
} from "../types/participant.types";
import { Spinner } from "../components/common/Spinner";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useGetConferenceQuery } from "../store/conference/conference";
import {
  AuthenticatedDownload,
  DownloadType,
} from "../components/common/AuthenticatedDownload";
import { BiMailSend } from "react-icons/bi";

interface ActionButtonProps {
  viewRegistrationDetailsCallback: Function;
  viewPresentationCallback?: Function;
  viewFormPreviewCallback?: Function;
  resendEmailCallback: Function;
}

const ActionButtons: React.FC<ActionButtonProps> = ({
  viewRegistrationDetailsCallback,
  viewPresentationCallback,
  viewFormPreviewCallback,
  resendEmailCallback,
}) => {
  const { i18n } = useLingui();
  return (
    <>
      <Tooltip label={i18n._("View Registration Details")}>
        <IconButton
          variant="outline"
          colorScheme="pink"
          aria-label={i18n._("View Registration Details")}
          fontSize="20px"
          icon={<AiOutlineInfo />}
          onClick={() => viewRegistrationDetailsCallback()}
          data-testid="view-registrations"
        />
      </Tooltip>
      {viewPresentationCallback && (
        <Tooltip label={i18n._("View Presentation")}>
          <IconButton
            variant="outline"
            colorScheme="whatsapp"
            aria-label={i18n._("View Presentation")}
            fontSize="20px"
            ml="8px"
            icon={<AiOutlineFundProjectionScreen />}
            onClick={() => viewPresentationCallback()}
            data-testid="view-presentation"
          />
        </Tooltip>
      )}
      {viewFormPreviewCallback && (
        <Tooltip label={i18n._("View Form Preview")}>
          <IconButton
            variant="outline"
            colorScheme="facebook"
            aria-label={i18n._("View Form")}
            fontSize="20px"
            ml="8px"
            icon={<AiFillEye />}
            onClick={() => viewFormPreviewCallback()}
            data-testid="view-form"
          />
        </Tooltip>
      )}
      <Tooltip label={i18n._("Resend email")}>
        <IconButton
          variant="outline"
          colorScheme="gray"
          aria-label={i18n._("Resend email")}
          fontSize="20px"
          ml="8px"
          icon={<BiMailSend />}
          onClick={() => resendEmailCallback()}
          data-testid="resend-email"
        />
      </Tooltip>
    </>
  );
};

const ActionButtonsMemo = React.memo(ActionButtons);

interface DownloadedBadgeProps {
  downloaded: ExcelDownloadType;
}
const DownloadedBadgeDraft: React.FC<DownloadedBadgeProps> = ({ downloaded }) =>
  downloaded === ExcelDownloadType.DOWNLOADED ? (
    <Badge
      variant="subtle"
      colorScheme="whatsapp"
      data-testid="downloaded-badge"
    >
      <Trans>Downloaded</Trans>
    </Badge>
  ) : (
    <Badge
      variant="subtle"
      colorScheme="red"
      data-testid="need-to-download-badge"
    >
      <Trans>Need to download</Trans>
    </Badge>
  );

const DownloadedBadge = React.memo(DownloadedBadgeDraft);

const RegistrationsPage = () => {
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const toast = useToast();

  const headerOfRegistrations: HeaderColumn[] = React.useMemo(
    () => [
      {
        name: "id",
        displayName: "",
        hide: true,
      },
      {
        name: "registrationId",
        displayName: i18n._("Registration Id"),
      },
      {
        name: "title",
        displayName: i18n._("Title"),
      },
      {
        name: "name",
        displayName: i18n._("Name"),
      },
      {
        name: "category",
        displayName: i18n._("Category"),
      },
      {
        name: "keepPresentation",
        displayName: i18n._("Keep Presentation"),
      },
      {
        name: "downloaded",
        displayName: i18n._("Downloaded"),
      },
      {
        name: "actions",
        displayName: i18n._("Actions"),
      },
    ],
    [i18n]
  );
  const { congressId } = useParams();
  const { data: congress } = useGetConferenceQuery(parseInt(congressId || ""));
  const { data: registrations = [], isLoading } =
    useListParticipantsByCongressIdQuery(parseInt(congressId || ""));
  const [
    resendEmail,
    { isSuccess: emailSentSuccess, isError: emailSentFailure },
  ] = useResendEmailMutation();
  const listOfParticipants = React.useMemo(
    () =>
      registrations.map((participant) => ({
        ...participant,
        name: `${participant.firstName} ${participant.lastName}`,
        title: mapParticipantTitle(participant.title),
        keepPresentation:
          participant.keepPresentation === KeepPresentationType.YES ? (
            <CheckCircleIcon color="mediumaquamarine" />
          ) : (
            "-"
          ),
        downloaded: (
          <DownloadedBadge downloaded={participant.downloadStatusType} />
        ),
        categoty: participant.category || "-",
        actions: (
          <ActionButtonsMemo
            viewRegistrationDetailsCallback={() => {
              console.trace("participant preview");
              navigate(`/authenticated/participant/${participant.id}`);
            }}
            viewPresentationCallback={
              participant.keepPresentation === KeepPresentationType.YES
                ? () => {
                    navigate(`/authenticated/presentation/${participant.id}`);
                  }
                : undefined
            }
            resendEmailCallback={() => resendEmail(participant.id)}
          />
        ),
      })),
    [registrations, navigate, resendEmail]
  );

  React.useEffect(() => {
    emailSentSuccess &&
      toast({
        title: "Email sikeresen kiküldve",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
  }, [toast, emailSentSuccess]);

  React.useEffect(() => {
    emailSentFailure &&
      toast({
        title: "Email kiküldése nem sikerült",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
  }, [toast, emailSentFailure]);

  const registrationInterval = React.useMemo(
    () =>
      `${registrations[registrations.length - 1]?.registrationId}-${
        registrations[0]?.registrationId
      }`,
    [registrations]
  );

  return (
    <BasicPageLayout
      pageTitle={`${congress ? congress.name : "..."} ${i18n._(
        "Registrations for"
      )}`}
      headerComponent={
        <HStack>
          <AuthenticatedDownload
            url={`${process.env.REACT_APP_BASE_URL}excel-export/${congressId}`}
            filename={`${congress?.name}-${registrationInterval}.xlsx`}
            congressId={congressId}
          >
            <Trans>Download</Trans>
          </AuthenticatedDownload>
          <AuthenticatedDownload
            url={`${process.env.REACT_APP_BASE_URL}excel-export-presentations/${congressId}`}
            filename={`${congress?.name}-${registrationInterval}-presentations.xlsx`}
            congressId={congressId}
            type={DownloadType.PRESENTATIONS}
          >
            <Trans>Download Presentations</Trans>
          </AuthenticatedDownload>
        </HStack>
      }
    >
      <DataGrid
        columns={headerOfRegistrations}
        data={listOfParticipants}
      ></DataGrid>
      {isLoading && <Spinner />}
    </BasicPageLayout>
  );
};

export default RegistrationsPage;
