import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ParticipantFullSummary } from "../../types/participant.types";

interface ParticipantState {
  participant: ParticipantFullSummary | null;
}

const initialState: ParticipantState | null = {
  participant: null,
};

export const participantSlice = createSlice({
  name: "participant",
  initialState,
  reducers: {
    setParticipant: (state, action: PayloadAction<ParticipantFullSummary>) => {
      state.participant = action.payload;
    },
  },
});

export const { setParticipant } = participantSlice.actions;

export const selectParticipant = (state: RootState) =>
  state.participant.participant;

export default participantSlice.reducer;
