"use client";

import { ReactElement } from "react";
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from "@chakra-ui/react";
import { FcApproval, FcSupport, FcLock } from "react-icons/fc";
import { useLingui } from "@lingui/react";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

interface ConferenceIconProps {
  w?: number;
  h?: number;
  style?: any;
}
export const ActiveConferenceIcon: React.FC<ConferenceIconProps> = ({
  w = 10,
  h = 10,
  style,
}) => <Icon as={FcApproval} w={w} h={h} style={style} />;
export const InactiveConferenceIcon: React.FC<ConferenceIconProps> = ({
  w = 10,
  h = 10,
  style,
}) => <Icon as={FcSupport} w={w} h={h} style={style} />;
export const FinishedConferenceIcon: React.FC<ConferenceIconProps> = ({
  w = 10,
  h = 10,
  style,
}) => <Icon as={FcLock} w={w} h={h} style={style} />;

export default function PageInstructions() {
  const { i18n } = useLingui();
  return (
    <Box p={4} mt={16}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<ActiveConferenceIcon />}
          title={i18n._("Active Conference")}
          text={i18n._("Conference is active. People can apply for it.")}
        />
        <Feature
          icon={<InactiveConferenceIcon />}
          title={i18n._("Conference is under organization")}
          text={i18n._("Users can't see these conferences.")}
        />
        <Feature
          icon={<FinishedConferenceIcon />}
          title={i18n._("Closed Conference")}
          text={i18n._(
            "There is no option for apply for submission or the happening is over."
          )}
        />
      </SimpleGrid>
    </Box>
  );
}
