import React from "react";

export const useGetHeightAndSendMessage = () => {
  const [height, setHeight] = React.useState<number>(0);
  const ref = React.useRef<HTMLDivElement>(null);
  const target: Window = window.top ?? window;

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newHeight = entry.contentRect.height + 30;
        if (newHeight !== height + 30) {
          setHeight(newHeight);
          target.postMessage(
            { height: newHeight, type: "congress-registration-form-change" },
            "*"
          );
        }
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [height, target, ref]);

  return ref;
};
