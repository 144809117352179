export enum Language {
  ENGLISH = "ENGLISH",
  HUNGARIAN = "HUNGARIAN",
}

export enum Currency {
  HUF = "HUF",
  EURO = "EURO",
}

export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  FINISHED = "FINISHED",
}

interface ConferenceConfiguration {
  mailingDetailsEnabled: boolean;
  paymentDetailsEnabled: boolean;
  participantFeeEnabled: boolean;
  presentationEnabled: boolean;
  lunchEnabled: boolean;
  activityEnabled: boolean;
  accommodationEnabled: boolean;
  otherInfoEnabled: boolean;
}

export interface ConferencePreview {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  status: Status;
}

export interface Conference extends ConferencePreview {
  language: Language;
  currency: Currency;
  configJson: ConferenceConfiguration;
  bookingStartDate: Date | string | undefined;
  bookingEndDate: Date | string | undefined;
}

export const emptyConference: Conference = {
  id: 0,
  name: "",
  startDate: "",
  endDate: "",
  status: Status.INACTIVE,
  language: Language.HUNGARIAN,
  currency: Currency.HUF,
  configJson: {
    mailingDetailsEnabled: true,
    paymentDetailsEnabled: false,
    participantFeeEnabled: false,
    presentationEnabled: false,
    lunchEnabled: false,
    activityEnabled: false,
    accommodationEnabled: false,
    otherInfoEnabled: false,
  },
  bookingStartDate: undefined,
  bookingEndDate: undefined,
};
