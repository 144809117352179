import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { useGetHeightAndSendMessage } from "../../utils/useGetHeightAndSendMessage";

interface PreviewLayoutProps {
  children?: React.ReactNode | React.ReactNode[];
}

const PreviewLayout: React.FC<PreviewLayoutProps> = ({ children }) => {
  const pageRef = useGetHeightAndSendMessage();
  return (
    <Flex
      minH={"100%"}
      align={"center"}
      bg={"transparent"}
      direction="column"
      my="12"
      w={"100%"}
      ref={pageRef}
    >
      <Stack
        spacing={4}
        w={"full"}
        bg={"transparent"}
        rounded={"xl"}
        maxW={"4xl"}
        boxShadow={"lg"}
        p={6}
      >
        {children}
      </Stack>
    </Flex>
  );
};

export default PreviewLayout;
