import {
  Heading,
  Stack,
  List,
  ListItem,
  ListIcon,
  Text,
  HStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import CurrencyContext from "../../context/CurrencyContext";
import { Trans } from "@lingui/macro";
import { FaCcMastercard, FaInfo } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { ParticipantFeeSummary } from "../../types/fee.types";
import { MultiUserMessageEscort } from "../common/MultiUserMessage";
import { formatPrice } from "../../utils/price-formatter";

interface EscortFeeSummaryProps {
  escortFee: ParticipantFeeSummary;
}

export const EscortFeeSummary: React.FC<EscortFeeSummaryProps> = ({
  escortFee,
}) => {
  const currency = useContext(CurrencyContext);
  return (
    <Stack
      px={0}
      py={3}
      justifyContent={{
        base: "flex-start",
        md: "space-between",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      alignItems={{ md: "center" }}
    >
      <Heading size={"md"} w={{ md: "32%" }}>
        <Trans>Escort Fee</Trans>
      </Heading>
      <List spacing={3} textAlign="start" w={{ md: "37%" }}>
        {escortFee.numberOfAttendants !== 0 && (
          <ListItem alignItems={"baseline"}>
            <HStack gap={0}>
              <ListIcon as={FaCcMastercard} color="gray.500" />
              <Text>
                {formatPrice(escortFee.totalPrice)} {currency} /{" "}
                <Trans>person</Trans>
              </Text>
            </HStack>
          </ListItem>
        )}
        <ListItem alignItems={"baseline"}>
          <HStack gap={0}>
            <ListIcon as={FaInfo} color="gray.500" />
            <Text>
              <MultiUserMessageEscort count={escortFee.numberOfAttendants} />
            </Text>
          </HStack>
        </ListItem>
        {escortFee.numberOfAttendants !== 0 && (
          <ListItem alignItems={"baseline"}>
            <HStack gap={0}>
              <ListIcon as={FiUsers} color="gray.500" />
              <Text>
                <Trans>Name of accompanying person(s)</Trans>:{" "}
                {escortFee.attendantsName}
              </Text>
            </HStack>
          </ListItem>
        )}
      </List>
      <Heading size={"xl"} w={{ md: "24%" }} textAlign={{ md: "end" }}>
        {formatPrice(escortFee.totalPrice * escortFee.numberOfAttendants)}{" "}
        {currency}
      </Heading>
    </Stack>
  );
};
