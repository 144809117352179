import { HStack, SkeletonText, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useGetTextByCongressIdQuery } from "../../store/additional-text/additional-text";
import "./AdditionalText.css";

interface AdditionalTextProps {
  congressId: number;
}

export const AdditionalText: React.FC<AdditionalTextProps> = ({
  congressId,
}) => {
  const { data: info = { text: "" }, isLoading } =
    useGetTextByCongressIdQuery(congressId);
  return (
    <Stack>
      <HStack>
        {isLoading ? (
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        ) : (
          <Text
            fontSize="m"
            mb="2"
            data-testid="additional-text"
            dangerouslySetInnerHTML={{ __html: info.text }}
            className="additional-text"
          />
        )}
      </HStack>
    </Stack>
  );
};
