import React from "react";
import { Box, Container } from "@chakra-ui/react";
import Heading from "../common/Heading";

interface BasicPageLayoutProps {
  pageTitle: string;
  children: React.ReactNode | React.ReactNode[];
  headerComponent?: React.ReactElement;
}

const BasicPageLayout: React.FC<BasicPageLayoutProps> = ({
  pageTitle,
  children,
  headerComponent,
}) => {
  return (
    <Box w="100%" p={4}>
      <Container maxW="2xl" p={4} centerContent>
        <Heading>{pageTitle}</Heading>
        {headerComponent}
      </Container>
      <Box w="100%" p={4}>
        {children}
      </Box>
    </Box>
  );
};

export default BasicPageLayout;
