import React from "react";
import { useParams } from "react-router-dom";
import { PageSkeleton } from "../components/common/PageSkeleton";
import { PresentationSummary } from "../components/summary/PresentationSummary";
import CongressHeader from "../components/common/Heading";
import { Trans } from "@lingui/macro";
import { useGetParticipantByIdQuery } from "../store/participant/participant";
import { ParticipantTitleType } from "../types/participant.types";
import PreviewLayout from "../components/layouts/Preview.layout";

const PresentationSummaryPage: React.FC = () => {
  const { participantId } = useParams();
  const registrationId = React.useMemo(
    () => (participantId ? parseInt(participantId) : null),
    [participantId]
  );
  const { data: participant, isLoading } = useGetParticipantByIdQuery(
    parseInt(participantId || "")
  );
  const title = React.useMemo(() => {
    switch (participant?.title) {
      case ParticipantTitleType.DR:
        return "Dr.";
      case ParticipantTitleType.DR_PROF:
        return "Dr. Prof.";
      case ParticipantTitleType.PROF:
        return "Prof.";
      default:
        return "";
    }
  }, [participant?.title]);

  return registrationId && !isLoading ? (
    <PreviewLayout>
      <CongressHeader>
        <Trans>Submitted presentations by</Trans> {title} {participant?.surname}{" "}
        {participant?.lastname}
      </CongressHeader>
      <PresentationSummary participantId={registrationId} />
    </PreviewLayout>
  ) : (
    <PageSkeleton />
  );
};

export default PresentationSummaryPage;
