import { Stack } from "@chakra-ui/react";
import React from "react";
import { DynamicFormStack } from "../common/ResponsiveFormStack";
import { FieldType, FormField } from "../../types/additional.types";
import { InputFormControl } from "../common/FormControlComponents";

const getFieldType = (type: FieldType): "text" | "number" =>
  type === FieldType.TEXT ? "text" : "number";

interface CustomFieldFormProps {
  fieldValues: Map<number, number | string>;
  setFieldValue: Function;
  fields: FormField[];
  prefix: string;
}

export const CustomFieldForm: React.FC<CustomFieldFormProps> = ({
  fieldValues,
  setFieldValue,
  fields = [],
  prefix,
}) => {
  React.useEffect(() => {
    const newFieldValues = new Map<number, number | string>();
    fields.forEach((field) => newFieldValues.set(field.id, ""));
    setFieldValue(`${prefix}.valuesByFieldId`, newFieldValues);
  }, [fields, prefix, setFieldValue]);

  const handleChange = React.useCallback(
    (id: number, value: string | number) => {
      const updatedFields = new Map(fieldValues);
      updatedFields.set(id, value);
      setFieldValue(`${prefix}.valuesByFieldId`, updatedFields);
    },
    [fieldValues, prefix, setFieldValue]
  );

  const getValue = React.useCallback(
    (fieldId: number) =>
      fieldValues && fieldValues.get && typeof fieldValues.get === "function"
        ? fieldValues.get(fieldId)
        : undefined,
    [fieldValues]
  );

  return (
    <Stack>
      <DynamicFormStack>
        {fields.map((field: FormField, index: number) => (
          <InputFormControl
            key={index}
            id={field.fieldName}
            label={field.fieldName}
            inputType={getFieldType(field.fieldType)}
            value={getValue(field.id)}
            name={field.fieldName}
            onChangeValue={(value: string | number) =>
              handleChange(field.id, value)
            }
            isRequired={field.isRequired}
            testId={`${prefix}-${field.fieldName}-custom-input`}
          />
        ))}
      </DynamicFormStack>
    </Stack>
  );
};
