import React from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import ResponsiveFormStack from "../common/ResponsiveFormStack";
import {
  InputFormControl,
  PreviewDropdownFormControl,
} from "../common/FormControlComponents";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ParticipantTitleType } from "../../types/participant.types";

interface ApplicationsDataProps {
  firstName: string;
  lastName: string;
  workplace: string;
  email: string;
  title: ParticipantTitleType;
  handleChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  setFieldValue: Function;
}

const ApplicationsData: React.FC<ApplicationsDataProps> = (props) => {
  const { i18n } = useLingui();
  const hu = React.useMemo(
    () => (i18n as any)._locale === "hu",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, (i18n as any)._locale]
  );
  return (
    <Stack>
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Participant information</Trans>
        </Text>
      </HStack>
      <ResponsiveFormStack
        firstElement={
          <PreviewDropdownFormControl
            id={"title"}
            label={i18n._("Title")}
            values={[
              { id: ParticipantTitleType.NONE, leftText: "-" },
              { id: ParticipantTitleType.DR, leftText: "Dr." },
              { id: ParticipantTitleType.PROF, leftText: "Prof." },
              { id: ParticipantTitleType.DR_PROF, leftText: "Prof. Dr." },
            ]}
            fieldName="participantRegister.title"
            setFieldValue={props.setFieldValue}
            selectedId={props.title}
            testId="participant-title-dropdown"
          />
        }
      />
      <ResponsiveFormStack
        testId="participant-name-and-surname-inputs"
        firstElement={
          <InputFormControl
            id="firstName"
            label={hu ? i18n._("Last name") : i18n._("First name")}
            inputType="text"
            value={props.firstName}
            name="participantRegister.firstName"
            onChange={props.handleChange}
            isRequired
            testId="participant-first-name-input"
          />
        }
        secondElement={
          <InputFormControl
            id="lastName"
            label={hu ? i18n._("First name") : i18n._("Last name")}
            inputType="text"
            value={props.lastName}
            name="participantRegister.surName"
            onChange={props.handleChange}
            isRequired
            testId="participant-last-name-input"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="workSpace"
            label={i18n._("Workplace")}
            inputType="text"
            value={props.workplace}
            name="participantRegister.workplace"
            onChange={props.handleChange}
            isRequired
            testId="participant-workplace-input"
          />
        }
        secondElement={
          <InputFormControl
            id="mailingEmail"
            label={i18n._("E-mail")}
            inputType="email"
            name="participantRegister.email"
            value={props.email}
            onChange={props.handleChange}
            isRequired
            testId="participant-email-input"
          />
        }
      />
    </Stack>
  );
};

export default ApplicationsData;
