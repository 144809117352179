import { PaymentType } from "./participant.types";

export enum AddressType {
  WORK = "WORK",
  HOME = "HOME",
  NONE = "NONE",
}

export interface AddressRequest {
  addressType: AddressType;
  city: string;
  street: string;
  postalCode: string;
  country: string;
  taxNumber: string;
  phone: string;
  valuesByFieldId: Map<number, string | number>;
}

export interface AddressSummary extends AddressRequest {
  customFieldValues: Record<string, string>;
}

export interface PaymentRequest {
  payment: PaymentType | undefined;
  city: string;
  street: string;
  postalCode: string;
  country: string;
  taxNumber: string;
  email: string;
  phone: string;
  fullName: string;
  valuesByFieldId: Map<number, string | number>;
}

export interface PaymentSummary extends PaymentRequest {
  payment: PaymentType;
  customFieldValues: Record<string, string>;
}
