import React from "react";
import {
  Divider,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";
import { DynamicFormStack } from "../common/ResponsiveFormStack";
import { Trans } from "@lingui/macro";
import {
  useListActiveProfessionalQuery,
  useListActiveSocialsQuery,
} from "../../store/activity/activity";
import { PreviewDropdownFormControl } from "../common/FormControlComponents";
import { useLingui } from "@lingui/react";
import { ActivityChoice } from "../../types/activity.types";
import CurrencyContext from "../../context/CurrencyContext";
import { formatDateForInput } from "../../utils/date-formatter";
import { formatPrice } from "../../utils/price-formatter";

interface ProgramsProps {
  congressId: number;
  setFieldValue: Function;
  professionals: ActivityChoice[];
  socials: ActivityChoice[];
}

enum ProgramChangeType {
  Professional = "professionals",
  Social = "socials",
}

const Programs: React.FC<ProgramsProps> = ({
  congressId,
  setFieldValue,
  professionals,
  socials,
}) => {
  const { i18n } = useLingui();
  const currency = React.useContext(CurrencyContext);
  const { data: socialList = [], isLoading: areSocialsLoading } =
    useListActiveSocialsQuery(congressId);
  const { data: professionalList = [], isLoading: areProfessionalsLoading } =
    useListActiveProfessionalQuery(congressId);

  React.useEffect(() => {
    const activityChoiceList: ActivityChoice[] = socialList.map((activity) => ({
      activityId: activity.id,
      count: 0,
    }));
    setFieldValue(ProgramChangeType.Social, activityChoiceList);
  }, [socialList, setFieldValue]);

  React.useEffect(() => {
    const activityChoiceList: ActivityChoice[] = professionalList.map(
      (activity) => ({
        activityId: activity.id,
        count: 0,
      })
    );
    setFieldValue(ProgramChangeType.Professional, activityChoiceList);
  }, [professionalList, setFieldValue]);

  return (
    <Stack>
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Activities</Trans>
        </Text>
      </HStack>
      {professionalList.length && (
        <>
          <HStack>
            <Text fontSize="l" mb="2">
              <Trans>Professional Activities</Trans>
            </Text>
          </HStack>
          <DynamicFormStack testId="preview-professional-form">
            {professionalList.map((professional, index) => (
              <PreviewDropdownFormControl
                key={index}
                id={`professional${index}`}
                label={`${professional.name} ${formatDateForInput(
                  professional.date
                )}`}
                values={[
                  { id: 0, leftText: i18n._("Not ordered") },
                  {
                    id: 1,
                    leftText: i18n._("1 person"),
                    rightText: `${formatPrice(professional.price)} ${currency}`,
                  },
                  {
                    id: 2,
                    leftText: i18n._("2 person"),
                    rightText: `${formatPrice(
                      professional.price * 2
                    )} ${currency}`,
                  },
                  {
                    id: 3,
                    leftText: i18n._("3 person"),
                    rightText: `${formatPrice(
                      professional.price * 3
                    )} ${currency}`,
                  },
                ]}
                fieldName={`professionals[${index}].count`}
                setFieldValue={setFieldValue}
                selectedId={professionals[index]?.count}
                testId={`professionals-dropdown-${professional.name}`}
              />
            ))}
          </DynamicFormStack>
        </>
      )}
      {professionalList.length && socials.length && (
        <Divider my={{ base: 4 }} />
      )}
      {socialList.length && (
        <>
          <HStack>
            <Text fontSize="l" mb="2">
              <Trans>Social Activities</Trans>
            </Text>
          </HStack>
          <DynamicFormStack testId="preview-social-form">
            {socialList.map((social, index) => (
              <PreviewDropdownFormControl
                key={index}
                id={`social${index}`}
                label={`${social.name} ${formatDateForInput(social.date)}`}
                values={[
                  { id: 0, leftText: i18n._("Not ordered") },
                  {
                    id: 1,
                    leftText: i18n._("1 person"),
                    rightText: `${formatPrice(social.price)} ${currency}`,
                  },
                  {
                    id: 2,
                    leftText: i18n._("2 person"),
                    rightText: `${formatPrice(social.price * 2)} ${currency}`,
                  },
                  {
                    id: 3,
                    leftText: i18n._("3 person"),
                    rightText: `${formatPrice(social.price * 3)} ${currency}`,
                  },
                ]}
                fieldName={`socials[${index}].count`}
                setFieldValue={setFieldValue}
                selectedId={socials[index]?.count}
                testId={`socials-dropdown-${social.name}`}
              />
            ))}
          </DynamicFormStack>
        </>
      )}
      {(areSocialsLoading || areProfessionalsLoading) && (
        <>
          <Skeleton height="50px" />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
          <Skeleton height="50px" />
        </>
      )}
    </Stack>
  );
};

export default Programs;
