import React from "react";
import {
  IconButton,
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
import { FiStar, FiSettings, FiMenu } from "react-icons/fi";
import { AiOutlineFundProjectionScreen, AiFillEye } from "react-icons/ai";
import { FaMoneyBillWave } from "react-icons/fa";
import { IconType } from "react-icons";
import { GiMeal } from "react-icons/gi";
import { MdHotel } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import { ReactText } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { conferenceApi } from "../../store/conference/conference";
import { formatDateForInput } from "../../utils/date-formatter";
import { useLingui } from "@lingui/react";
import { Conference, Currency } from "../../types/conference.types";
import CurrencyContext from "../../context/CurrencyContext";
import IntervalContext from "../../context/InteralContext";

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
  disable?: boolean;
  testId?: string;
}

export const CongressPageLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { congressId } = useParams();
  const shallDisable = React.useMemo(() => congressId === "new", [congressId]);
  const [fetchCongress, { data: congress }] =
    conferenceApi.endpoints.getConference.useLazyQuery();

  React.useEffect(() => {
    if (congressId && congressId !== "new") {
      fetchCongress(congressId);
    }
  }, [congressId, fetchCongress]);

  const currency = React.useMemo(() => {
    return congress?.currency === Currency.HUF ? "Ft" : "€" || "";
  }, [congress]);

  return (
    <CurrencyContext.Provider value={currency}>
      <IntervalContext.Provider
        value={{
          startDate: formatDateForInput(congress?.startDate),
          endDate: formatDateForInput(congress?.endDate),
        }}
      >
        <Box
          minH="100vh"
          bg={useColorModeValue("gray.100", "gray.900")}
          my={{ base: -2 }}
          mx={{ base: -4 }}
          w="100%"
        >
          <SidebarContent
            onClose={() => onClose}
            display={{ base: "none", md: "block" }}
            shallDisable={shallDisable}
            congressId={congressId}
            congress={congress}
          />
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
          >
            <DrawerContent>
              <SidebarContent
                onClose={onClose}
                shallDisable={shallDisable}
                congressId={congressId}
              />
            </DrawerContent>
          </Drawer>
          {/* mobilenav */}
          <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
          <Box ml={{ base: 0, md: 60 }} p="4">
            <Container centerContent w="100%" mb="2">
              <Heading as="h5" size="sm">
                {congress?.name}
              </Heading>{" "}
              <Text fontSize="sm">
                {formatDateForInput(congress?.startDate)} -{" "}
                {formatDateForInput(congress?.endDate)}
              </Text>
            </Container>
            <Outlet />
          </Box>
        </Box>
      </IntervalContext.Provider>
    </CurrencyContext.Provider>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
  shallDisable: boolean;
  congressId?: string;
  congress?: Conference;
}

const SidebarContent = ({
  onClose,
  shallDisable,
  congressId = "",
  congress = {
    configJson: {
      presentationEnabled: false,
      participantFeeEnabled: false,
      activityEnabled: false,
      lunchEnabled: false,
      accommodationEnabled: false,
      otherInfoEnabled: false,
    },
  } as Conference,
  ...rest
}: SidebarProps) => {
  const { i18n } = useLingui();
  const LinkItems: Array<LinkItemProps> = React.useMemo(
    () => [
      {
        name: i18n._("Module Settings"),
        icon: FiSettings,
        href: "/authenticated/congress/<congressId>/module-settings",
        testId: "module-settings-page",
      },
      {
        name: i18n._("Additional Fields"),
        icon: AiOutlineFundProjectionScreen,
        href: "/authenticated/congress/<congressId>/additional-fields",
        disable: congressId === "new" || (shallDisable && false),
        testId: "additional-fields-page",
      },
      {
        name: i18n._("Presentations"),
        icon: AiOutlineFundProjectionScreen,
        href: "/authenticated/congress/<congressId>/presentations",
        disable:
          !congress.configJson.presentationEnabled || (shallDisable && false),
        testId: "presentations-page",
      },
      {
        name: i18n._("Fees"),
        icon: FaMoneyBillWave,
        href: "/authenticated/congress/<congressId>/fees",
        disable:
          !congress.configJson.participantFeeEnabled || (shallDisable && false),
        testId: "fees-page",
      },
      {
        name: i18n._("Accomodations"),
        icon: MdHotel,
        href: "/authenticated/congress/<congressId>/accomodations",
        disable:
          !congress.configJson.accommodationEnabled || (shallDisable && false),
        testId: "accomodations-page",
      },
      {
        name: i18n._("Meals"),
        icon: GiMeal,
        href: "/authenticated/congress/<congressId>/meals",
        disable: !congress.configJson.lunchEnabled || (shallDisable && false),
        testId: "meals-page",
      },
      {
        name: i18n._("Activities"),
        icon: FiStar,
        href: "/authenticated/congress/<congressId>/activities",
        disable:
          !congress.configJson.activityEnabled || (shallDisable && false),
        testId: "activities-page",
      },
      {
        name: i18n._("Other Information"),
        icon: BsInfoCircle,
        href: "/authenticated/congress/<congressId>/other-informations",
        disable:
          !congress.configJson.otherInfoEnabled || (shallDisable && false),
        testId: "other-informations-page",
      },
      {
        name: i18n._("Preview"),
        icon: AiFillEye,
        href: "/preview/<congressId>",
        disable: congressId === "new" || (shallDisable && false),
        testId: "preview-page",
      },
    ],
    [
      i18n,
      congress.configJson.presentationEnabled,
      congress.configJson.participantFeeEnabled,
      congress.configJson.activityEnabled,
      congress.configJson.lunchEnabled,
      congress.configJson.accommodationEnabled,
      congress.configJson.otherInfoEnabled,
      shallDisable,
      congressId,
    ]
  );
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      {LinkItems.map((link) => {
        const href = link.href.replace("<congressId>", congressId);
        return (
          <NavItem
            key={link.name}
            icon={link.icon}
            href={href}
            disabled={!!link.disable}
            testId={link.testId}
          >
            {link.name}
          </NavItem>
        );
      })}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  disabled: boolean;
  href: string;
  testId?: string;
}

interface FragmentProps {
  to?: any;
  children?: React.ReactElement | React.ReactElement[];
}
const Fragment: React.FC<FragmentProps> = ({ children }) => <>{children}</>;

const NavItem = ({
  icon,
  children,
  href,
  disabled,
  testId,
  ...rest
}: NavItemProps) => {
  const Wrapper = disabled ? Fragment : Link;
  return (
    <Wrapper to={href} data-testid={testId}>
      <Box style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: disabled ? "transparent" : "cyan.400",
            color: disabled ? "gray.900" : "white",
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: disabled ? "gray.900" : "white",
              }}
              as={icon}
              color={disabled ? "gray.900" : "lightcoral"}
            />
          )}
          {children}
        </Flex>
      </Box>
    </Wrapper>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};

export default CongressPageLayout;
