import { AddressType } from "../types/address.types";
import { ParticipantTitleType, PaymentType } from "../types/participant.types";

export const formatTitle = (title: ParticipantTitleType): string => {
  switch (title) {
    case ParticipantTitleType.DR:
      return "Dr.";
    case ParticipantTitleType.PROF:
      return "Prof.";
    case ParticipantTitleType.DR_PROF:
      return "Dr. Prof.";
    default:
      return "";
  }
};

export const formatAddressType = (type: AddressType): string => {
  switch (type) {
    case AddressType.HOME:
      return "Home Address";
    case AddressType.WORK:
      return "Workplace Address";
    default:
      return "";
  }
};

export const formatPaymentType = (type: PaymentType): string => {
  switch (type) {
    case PaymentType.CARD:
      return "Debit or Credit card";
    case PaymentType.TRANSFER:
      return "For myself by bank transfer";
    case PaymentType.OTHER:
      return "Workspace, sponsor by bank transfer";
    default:
      return "";
  }
};
