import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLingui } from "@lingui/react";
import { useDispatch } from "react-redux";
import PreviewContent from "../components/preview/PreviewContent";

const PreviewPage = () => {
  const { congressId } = useParams();
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const eventId = React.useMemo(
    () => (congressId ? parseInt(congressId) : null),
    [congressId]
  );

  React.useEffect(() => {
    //FIXME somewhy when we load the page sync, it freezes because of RTK cache
    // don't know a proper solution right now.
    setTimeout(() => setShow(true), 1);
  }, [dispatch]);

  return eventId && show ? (
    <PreviewContent
      i18n={i18n}
      congressId={eventId}
      dispatch={dispatch}
      location={location}
      navigate={navigate}
    />
  ) : (
    <></>
  );
};

export default PreviewPage;
