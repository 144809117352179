import {
  Container,
  Heading,
  Highlight,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { ParticipantSummary } from "../components/summary/ParticipantSummary";
import { useSelector } from "react-redux";
import { selectParticipant } from "../store/participant/participantSlice";
import { PageSkeleton } from "../components/common/PageSkeleton";
import PreviewLayout from "../components/layouts/Preview.layout";
import { CongressButtonScheme } from "../components/common/CongressButton";
import { useLingui } from "@lingui/react";

const SuccessRegistrationPage = () => {
  const { registrationId } = useParams();
  const { i18n } = useLingui();
  const participant = useSelector(selectParticipant);

  const title = React.useMemo(() => i18n._("Successful registration!"), [i18n]);

  const id = React.useMemo(
    () => (registrationId ? parseInt(registrationId) : null),
    [registrationId]
  );

  return (
    <PreviewLayout>
      {id && participant ? (
        <>
          <Container centerContent>
            <VStack spacing={2} textAlign="center">
              <HStack
                width={{
                  base: "100%",
                }}
                textAlign={"center"}
                alignItems={"baseline"}
              >
                <Heading as="h1" fontSize="4xl">
                  <Highlight
                    query={[i18n._("registration!")]}
                    styles={{
                      bgGradient: CongressButtonScheme.GREEN_SCHEME,
                      bgClip: "text",
                    }}
                  >
                    {title}
                  </Highlight>
                </Heading>
              </HStack>
            </VStack>
          </Container>
          <ParticipantSummary registrationId={id} participant={participant} />
        </>
      ) : (
        <PageSkeleton />
      )}
    </PreviewLayout>
  );
};

export default SuccessRegistrationPage;
