import { Heading, Stack, HStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import CurrencyContext from "../../context/CurrencyContext";
import { Trans } from "@lingui/macro";
import { CongressButtonScheme } from "../common/CongressButton";
import { formatPrice } from "../../utils/price-formatter";

interface FinalFeeProps {
  totalPrice: number | null;
}

export const FinalFee: React.FC<FinalFeeProps> = ({ totalPrice }) => {
  const currency = useContext(CurrencyContext);
  return (
    <Stack
      pb={5}
      alignItems={"center"}
      justifyContent={{
        base: "flex-start",
        md: "space-around",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
    >
      <HStack
        width={{
          base: "100%",
          md: "40%",
        }}
        textAlign={"left"}
        alignItems={"baseline"}
      >
        <Heading size={"lg"}>
          <Trans>Total</Trans>
        </Heading>
        <Heading
          size={"lg"}
          bgGradient={CongressButtonScheme.GREEN_SCHEME}
          bgClip="text"
        >
          <Trans>fee</Trans>
        </Heading>
      </HStack>
      <Stack
        width={{
          base: "100%",
          md: "60%",
        }}
      >
        <Heading
          textAlign={{ base: "left", md: "right" }}
          size={"xl"}
          data-testid="final-fee"
        >
          {formatPrice(totalPrice)} {currency}
        </Heading>
      </Stack>
    </Stack>
  );
};
