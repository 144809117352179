export enum FeeType {
  DEFAULT = "DEFAULT",
  ESCORT = "ESCORT",
}

export interface Fee {
  id: number;
  name: string;
  price: number;
  lunchPrice: number;
  totalPrice: number;
  congressEventId: number;
  enabled: boolean;
  ordinal: number;
  feeType: FeeType;
}

export const createEmptyFee = (
  congressEventId: number,
  feeType: FeeType
): Fee => ({
  id: 0,
  ordinal: 0,
  name: "",
  congressEventId,
  enabled: true,
  price: 0,
  lunchPrice: 0,
  totalPrice: 0,
  feeType,
});

export interface PartecipantFeeRequest {
  participationFeeId: number | undefined;
}

export interface EscortFeeRequest extends PartecipantFeeRequest {
  numberOfAttendants: number;
  attendantsName: string;
}

export interface ParticipantFeeSummary {
  name: string;
  organizingPrice: number;
  lunchPrice: number;
  totalPrice: number;
  feeType: FeeType;
  numberOfAttendants: number;
  attendantsName: string;
}
