import React from "react";

interface Interval {
  startDate?: string;
  endDate?: string;
}

const IntervalContext = React.createContext<Interval>({});

export default IntervalContext;
