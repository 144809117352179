import React from "react";
import { useParams } from "react-router-dom";
import { ParticipantSummary } from "../components/summary/ParticipantSummary";
import { PageSkeleton } from "../components/common/PageSkeleton";
import PreviewLayout from "../components/layouts/Preview.layout";
import { useGetParticipantSummaryByIdQuery } from "../store/participant/participant";
import { useLingui } from "@lingui/react";
import { Language } from "../types/conference.types";

const ParticipantSummaryPage = () => {
  const { participantId } = useParams();
  const { i18n } = useLingui();
  const registrationId = React.useMemo(
    () => (participantId ? parseInt(participantId) : null),
    [participantId]
  );
  const { data: participant, isLoading: isParticipantLoading } =
    useGetParticipantSummaryByIdQuery(
      registrationId || parseInt(participantId || "")
    );

  React.useEffect(() => {
    if (participant) {
      const language =
        participant.congressEvent.language === Language.ENGLISH ? "en" : "hu";
      i18n.activate(language);
    }
  }, [participant, i18n]);

  return registrationId && !isParticipantLoading && participant ? (
    <PreviewLayout>
      <ParticipantSummary
        registrationId={registrationId}
        participant={participant}
      />
    </PreviewLayout>
  ) : (
    <PageSkeleton />
  );
};

export default ParticipantSummaryPage;
