import React from "react";
import { useListActiveBillingFieldsQuery } from "../../store/additional-fields/additional-fields";
import { CustomFieldForm } from "./CustomFieldForm";

interface CustomBillingRegistrationProps {
  congressId: number;
  fieldValues: Map<number, number | string>;
  setFieldValue: Function;
}

export const CustomBillingRegistration: React.FC<
  CustomBillingRegistrationProps
> = ({ congressId, fieldValues, setFieldValue }) => {
  const { data: fields = [] } = useListActiveBillingFieldsQuery(congressId);

  return (
    <CustomFieldForm
      fields={fields}
      fieldValues={fieldValues}
      setFieldValue={setFieldValue}
      prefix="payment"
    />
  );
};
