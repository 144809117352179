import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axios-query";
import { EnableRequest, UpdateOrdinal } from "../../types/activity.types";
import { Fee, FeeType } from "../../types/fee.types";

export const feeApi = createApi({
  reducerPath: "feeApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "ParticipantFee",
    "EscortFee",
    "PublicParticipantFee",
    "PublicEscortFee",
  ],
  endpoints: (builder) => ({
    listParticipantFeesByCongressId: builder.query<Fee[], number | string>({
      query: (congressId: number) => ({
        url: `participant-fee/${congressId}/type/${FeeType.DEFAULT}`,
      }),
      providesTags: ["ParticipantFee"],
    }),
    listActiveParticipantFeesByCongressId: builder.query<
      Fee[],
      number | string
    >({
      query: (congressId: number) => ({
        url: `participant-fee/${congressId}/type/${FeeType.DEFAULT}/enabled/public`,
      }),
      providesTags: ["PublicParticipantFee"],
    }),
    createParticipantFee: builder.mutation<Fee, Fee>({
      query: (fee: Fee) => ({
        url: `participant-fee/`,
        method: "POST",
        data: fee,
      }),
      invalidatesTags: ["ParticipantFee", "PublicParticipantFee"],
    }),
    updateParticipantFee: builder.mutation<Fee, Fee>({
      query: (fee: Fee) => ({
        url: `participant-fee/${fee.id}`,
        method: "PUT",
        data: fee,
      }),
      invalidatesTags: ["ParticipantFee", "PublicParticipantFee"],
    }),
    activateParticipantFee: builder.mutation<Fee, EnableRequest>({
      query: (fee: EnableRequest) => ({
        url: `participant-fee/${fee.id}/enabled`,
        method: "PUT",
        data: fee,
      }),
      invalidatesTags: ["ParticipantFee", "PublicParticipantFee"],
    }),
    updateParticipantFeeOrdinal: builder.mutation<Fee, UpdateOrdinal>({
      query: (fee: UpdateOrdinal) => ({
        url: `participant-fee/${fee.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: fee.newOrdinal },
      }),
      invalidatesTags: ["ParticipantFee", "PublicParticipantFee"],
    }),
    listEscortFeesByCongressId: builder.query<Fee[], number | string>({
      query: (congressId: number) => ({
        url: `participant-fee/${congressId}/type/${FeeType.ESCORT}`,
      }),
      providesTags: ["EscortFee"],
    }),
    listActiveEscortFeesByCongressId: builder.query<Fee[], number | string>({
      query: (congressId: number) => ({
        url: `participant-fee/${congressId}/type/${FeeType.ESCORT}/enabled/public`,
      }),
      providesTags: ["PublicEscortFee"],
    }),
    createEscortFee: builder.mutation<Fee, Fee>({
      query: (escortFee: Fee) => ({
        url: `participant-fee/`,
        method: "POST",
        data: escortFee,
      }),
      invalidatesTags: ["EscortFee", "PublicEscortFee"],
    }),
    updateEscortFee: builder.mutation<Fee, Fee>({
      query: (escortFee: Fee) => ({
        url: `participant-fee/${escortFee.id}`,
        method: "PUT",
        data: escortFee,
      }),
      invalidatesTags: ["EscortFee", "PublicEscortFee"],
    }),
    activateEscortFee: builder.mutation<Fee, EnableRequest>({
      query: (escortFee: EnableRequest) => ({
        url: `participant-fee/${escortFee.id}/enabled`,
        method: "PUT",
        data: escortFee,
      }),
      invalidatesTags: ["EscortFee", "PublicEscortFee"],
    }),
  }),
});

export const {
  useListParticipantFeesByCongressIdQuery,
  useListActiveParticipantFeesByCongressIdQuery,
  useCreateParticipantFeeMutation,
  useUpdateParticipantFeeMutation,
  useActivateParticipantFeeMutation,
  useUpdateParticipantFeeOrdinalMutation,
  useListEscortFeesByCongressIdQuery,
  useListActiveEscortFeesByCongressIdQuery,
  useCreateEscortFeeMutation,
  useUpdateEscortFeeMutation,
  useActivateEscortFeeMutation,
} = feeApi;
