import React from "react";
import { useListActiveAdditionalFieldsQuery } from "../../store/additional-fields/additional-fields";
import { CustomFieldForm } from "./CustomFieldForm";

interface CustomFieldRegistrationProps {
  congressId: number;
  fieldValues: Map<number, number | string>;
  setFieldValue: Function;
}

export const CustomFieldRegistration: React.FC<
  CustomFieldRegistrationProps
> = ({ congressId, fieldValues, setFieldValue }) => {
  const { data: fields = [] } = useListActiveAdditionalFieldsQuery(congressId);

  return (
    <CustomFieldForm
      fields={fields}
      fieldValues={fieldValues}
      setFieldValue={setFieldValue}
      prefix="participantRegister"
    />
  );
};
