import { createApi } from "@reduxjs/toolkit/query/react";
import { Conference, ConferencePreview } from "../../types/conference.types";
import axiosBaseQuery from "../../utils/axios-query";

export const conferenceApi = createApi({
  reducerPath: "conferenceApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Conferences", "Conference"],
  endpoints: (builder) => ({
    listConferences: builder.query<ConferencePreview[], string>({
      query: () => ({
        url: `congress-event/`,
      }),
      providesTags: ["Conferences"],
    }),
    getConference: builder.query<Conference, number | string>({
      query: (id) => ({ url: `congress-event/${id}/public` }),
      providesTags: ["Conference"],
    }),
    createConference: builder.mutation<Conference, Conference>({
      query: (conference: Conference) => ({
        url: `congress-event/`,
        method: "POST",
        data: conference,
      }),
      invalidatesTags: ["Conferences", "Conference"],
    }),
    updateConference: builder.mutation<Conference, Conference>({
      query: (conference: Conference) => ({
        url: `congress-event/${conference.id}`,
        method: "PUT",
        data: conference,
      }),
      invalidatesTags: ["Conferences", "Conference"],
    }),
  }),
});

export const {
  useListConferencesQuery,
  useGetConferenceQuery,
  useCreateConferenceMutation,
  useUpdateConferenceMutation,
} = conferenceApi;
