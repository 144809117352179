export const getCorrectDate = (
  eventDate: string | Date | undefined,
  bookingDate: string | Date | undefined,
  isArrival: boolean = true
): string | undefined | Date => {
  if (eventDate === undefined && bookingDate === undefined) {
    return undefined;
  } else if (eventDate === undefined) {
    return bookingDate;
  } else if (bookingDate === undefined) {
    return eventDate;
  }

  const _eventDate = new Date(eventDate);
  const _bookingDate = new Date(bookingDate);
  if (isArrival) {
    return _eventDate > _bookingDate ? eventDate : bookingDate;
  } else {
    return _eventDate < _bookingDate ? eventDate : bookingDate;
  }
};
