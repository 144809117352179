import React from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import ResponsiveFormStack from "../common/ResponsiveFormStack";
import {
  InputFormControl,
  PreviewDropdownFormControl,
} from "../common/FormControlComponents";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { AddressType } from "../../types/address.types";

interface MailingAddressProps {
  addressType: AddressType;
  city: string;
  street: string;
  postalCode: string;
  country: string;
  phone: string;
  handleChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  setFieldValue: Function;
}

const MailingAddress: React.FC<MailingAddressProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <Stack>
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Mailing address</Trans>
        </Text>
      </HStack>
      <ResponsiveFormStack
        firstElement={
          <PreviewDropdownFormControl
            id={"addressType"}
            label={i18n._("Type of address")}
            values={[
              { id: AddressType.WORK, leftText: i18n._("Workplace address") },
              { id: AddressType.HOME, leftText: i18n._("Home") },
            ]}
            fieldName="address.addressType"
            setFieldValue={props.setFieldValue}
            selectedId={props.addressType}
            isRequired
            defaultPreviewText={i18n._("Please select!")}
            testId="mailing-address-type-dropdown"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="mailingCountry"
            label={i18n._("Country")}
            inputType="text"
            name="address.country"
            value={props.country}
            onChange={props.handleChange}
            testId="mailing-country-input"
          />
        }
        secondElement={
          <InputFormControl
            id="mailingPostalCode"
            label={i18n._("Postal code")}
            inputType="text"
            name="address.postalCode"
            value={props.postalCode}
            onChange={props.handleChange}
            testId="mailing-postal-code-input"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="mailingCity"
            label={i18n._("City")}
            inputType="text"
            name="address.city"
            value={props.city}
            onChange={props.handleChange}
            testId="mailing-city-input"
          />
        }
        secondElement={
          <InputFormControl
            id="mailingAddress"
            label={i18n._("Address")}
            inputType="text"
            name="address.street"
            value={props.street}
            onChange={props.handleChange}
            testId="mailing-address-input"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="mailingMobile"
            label={i18n._("Mobile")}
            inputType="tel"
            name="address.phone"
            value={props.phone}
            onChange={props.handleChange}
            testId="mailing-mobile-input"
          />
        }
      />
    </Stack>
  );
};

export default MailingAddress;
