import { Select } from "@chakra-ui/react";
import React from "react";

export interface DropdownItem {
  id: string | number | undefined;
  text: string | React.ReactElement | React.ReactElement[];
}

export interface DropdownProps {
  values: DropdownItem[];
  onChangeValue?: Function;
  selectedId?: string | number;
  disabled?: boolean;
  defaultText?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  testId?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  values,
  onChange = () => {},
  onChangeValue = () => {},
  selectedId,
  disabled = false,
  defaultText = "",
  name = "",
  testId,
}) => {
  const onChangeCallback = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(event);
      onChangeValue(event.target.value);
    },
    [onChange, onChangeValue]
  );
  return (
    <Select
      onChange={onChangeCallback}
      value={selectedId}
      disabled={disabled}
      name={name}
      data-testid={testId}
    >
      <option value={undefined}>{defaultText}</option>
      {values.map((value) => (
        <option value={value.id} key={value.id}>
          {value.text}
        </option>
      ))}
    </Select>
  );
};

export default React.memo(Dropdown);
