import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import participantReducer from "./participant/participantSlice";
import logger from "redux-logger";
import { conferenceApi } from "./conference/conference";
import { userApi } from "./user/user";
import { participantApi } from "./participant/participant";
import { activityApi } from "./activity/activity";
import { accomodationApi } from "./accomodation/accomodation";
import { feeApi } from "./fee/fee";
import { presentationApi } from "./presentation/presentation";
import { additionalTextApi } from "./additional-text/additional-text";
import { additionalFieldApi } from "./additional-fields/additional-fields";

export const store = configureStore({
  reducer: {
    user: userReducer,
    participant: participantReducer,
    [conferenceApi.reducerPath]: conferenceApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [participantApi.reducerPath]: participantApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [accomodationApi.reducerPath]: accomodationApi.reducer,
    [feeApi.reducerPath]: feeApi.reducer,
    [presentationApi.reducerPath]: presentationApi.reducer,
    [additionalTextApi.reducerPath]: additionalTextApi.reducer,
    [additionalFieldApi.reducerPath]: additionalFieldApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      conferenceApi.middleware,
      userApi.middleware,
      participantApi.middleware,
      activityApi.middleware,
      accomodationApi.middleware,
      feeApi.middleware,
      presentationApi.middleware,
      additionalTextApi.middleware,
      additionalFieldApi.middleware,
      logger,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
