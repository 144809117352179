import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

export interface HeaderColumn {
  name: string;
  displayName: string;
  hide?: boolean;
}

interface DataGridHeaderProps {
  columns: HeaderColumn[];
}

const DataGridHeader: React.FC<DataGridHeaderProps> = ({ columns }) => (
  <Thead>
    <Tr>
      {columns.map((column, index) =>
        column.hide ? (
          <Th
            key={`header-column-${column.name}-${index}`}
            display={"none"}
          ></Th>
        ) : (
          <Th key={`header-column-${column.name}-${index}`}>
            {column.displayName}
          </Th>
        )
      )}
    </Tr>
  </Thead>
);
const DataGridHeaderMemo = React.memo(DataGridHeader);

interface DataGridBodyProps {
  columns: HeaderColumn[];
  data: any[];
}

const DataGridBody: React.FC<DataGridBodyProps> = ({ columns, data }) => (
  <Tbody>
    {data.map((row) => (
      <Tr key={`body-row-${row.id}`} data-testid={row?.testId}>
        {columns.map((column, index) =>
          column.hide ? (
            <Td
              key={`body-row-${row.id}-column-${column.name}-${index}`}
              display={"none"}
            ></Td>
          ) : (
            <Td
              key={`body-row-${row.id}-column-${column.name}-${index}`}
              position={"relative"}
            >
              {row[column.name]}
            </Td>
          )
        )}
      </Tr>
    ))}
  </Tbody>
);
const DataGridBodyMemo = React.memo(DataGridBody);

interface DataGridProps {
  columns: HeaderColumn[];
  data: any[];
}

const DataGrid: React.FC<DataGridProps> = ({ columns, data }) => (
  <TableContainer>
    <Table variant="simple" size="md">
      <DataGridHeaderMemo columns={columns} />
      <DataGridBodyMemo columns={columns} data={data} />
    </Table>
  </TableContainer>
);

export default DataGrid;
