import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axios-query";
import {
  Activity,
  ActivityType,
  EnableRequest,
  UpdateOrdinal,
} from "../../types/activity.types";

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Activity",
    "Meal",
    "Social",
    "Professional",
    "Info",
    "PublicMeal",
    "PublicSocial",
    "PublicProfessional",
    "PublicInfo",
  ],
  endpoints: (builder) => ({
    listSocialsForCongressId: builder.query<Activity[], number>({
      query: (congressId) => ({
        url: `activity/${congressId}/${ActivityType.SOCIAL}`,
      }),
      providesTags: ["Social"],
    }),
    listActiveSocials: builder.query<Activity[], number>({
      query: (congressId) => ({
        url: `activity/${congressId}/${ActivityType.SOCIAL}/enabled/public`,
      }),
      providesTags: ["PublicSocial"],
    }),
    addSocial: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/`,
        method: "POST",
        data: activity,
      }),
      invalidatesTags: ["Social", "PublicSocial"],
    }),
    updateSocial: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/${activity.id}`,
        method: "PUT",
        data: activity,
      }),
      invalidatesTags: ["Social", "PublicSocial"],
    }),
    activateSocial: builder.mutation<Activity, EnableRequest>({
      query: (activity: EnableRequest) => ({
        url: `activity/${activity.id}/enabled`,
        method: "PUT",
        data: activity,
      }),
      invalidatesTags: ["Social", "PublicSocial"],
    }),
    updateSocialOrdinal: builder.mutation<Activity, UpdateOrdinal>({
      query: (activity: UpdateOrdinal) => ({
        url: `activity/${activity.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: activity.newOrdinal },
      }),
      invalidatesTags: ["Social", "PublicSocial"],
    }),
    listProfessionalForCongressId: builder.query<Activity[], number>({
      query: (congressId) => ({
        url: `activity/${congressId}/${ActivityType.PROFESSIONAL}`,
      }),
      providesTags: ["Professional"],
    }),
    listActiveProfessional: builder.query<Activity[], number>({
      query: (congressId) => ({
        url: `activity/${congressId}/${ActivityType.PROFESSIONAL}/enabled/public`,
      }),
      providesTags: ["PublicProfessional"],
    }),
    addProfessional: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/`,
        method: "POST",
        data: activity,
      }),
      invalidatesTags: ["Professional", "PublicProfessional"],
    }),
    updateProfessional: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/${activity.id}`,
        method: "PUT",
        data: activity,
      }),
      invalidatesTags: ["Professional", "PublicProfessional"],
    }),
    activateProfessional: builder.mutation<Activity, EnableRequest>({
      query: (activity: EnableRequest) => ({
        url: `activity/${activity.id}/enabled`,
        method: "PUT",
        data: activity,
      }),
      invalidatesTags: ["Professional", "PublicProfessional"],
    }),
    updateProfessionalOrdinal: builder.mutation<Activity, UpdateOrdinal>({
      query: (activity: UpdateOrdinal) => ({
        url: `activity/${activity.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: activity.newOrdinal },
      }),
      invalidatesTags: ["Professional", "PublicProfessional"],
    }),
    listMealsForCongressId: builder.query<Activity[], number>({
      query: (congressId) => ({
        url: `activity/${congressId}/${ActivityType.MEAL}`,
      }),
      providesTags: ["Meal"],
    }),
    listActiveMealsForCongressId: builder.query<Activity[], number>({
      query: (congressId) => ({
        url: `activity/${congressId}/${ActivityType.MEAL}/enabled/public`,
      }),
      providesTags: ["PublicMeal"],
    }),
    addMeal: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/`,
        method: "POST",
        data: activity,
      }),
      invalidatesTags: ["Meal", "PublicMeal"],
    }),
    updateMeal: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/${activity.id}`,
        method: "PUT",
        data: activity,
      }),
      invalidatesTags: ["Meal", "PublicMeal"],
    }),
    activateMeal: builder.mutation<Activity, EnableRequest>({
      query: (activity: EnableRequest) => ({
        url: `activity/${activity.id}/enabled`,
        method: "PUT",
        data: activity,
      }),
      invalidatesTags: ["Meal", "PublicMeal"],
    }),
    updateMealOrdinal: builder.mutation<Activity, UpdateOrdinal>({
      query: (activity: UpdateOrdinal) => ({
        url: `activity/${activity.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: activity.newOrdinal },
      }),
      invalidatesTags: ["Meal", "PublicMeal"],
    }),
    listInfoForCongressId: builder.query<Activity[], number>({
      query: (congressId) => ({
        url: `activity/${congressId}/${ActivityType.INFO}`,
      }),
      providesTags: ["Info"],
    }),
    addInfo: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/`,
        method: "POST",
        data: activity,
      }),
      invalidatesTags: ["Info"],
    }),
    updateInfo: builder.mutation<Activity, Activity>({
      query: (activity: Activity) => ({
        url: `activity/${activity.id}`,
        method: "PUT",
        data: activity,
      }),
      invalidatesTags: ["Info"],
    }),
  }),
});

export const {
  useListSocialsForCongressIdQuery,
  useListActiveSocialsQuery,
  useAddSocialMutation,
  useUpdateSocialMutation,
  useActivateSocialMutation,
  useUpdateSocialOrdinalMutation,
  useListProfessionalForCongressIdQuery,
  useListActiveProfessionalQuery,
  useAddProfessionalMutation,
  useUpdateProfessionalMutation,
  useActivateProfessionalMutation,
  useUpdateProfessionalOrdinalMutation,
  useListMealsForCongressIdQuery,
  useListActiveMealsForCongressIdQuery,
  useAddMealMutation,
  useUpdateMealMutation,
  useActivateMealMutation,
  useUpdateMealOrdinalMutation,
  useListInfoForCongressIdQuery,
  useAddInfoMutation,
  useUpdateInfoMutation,
} = activityApi;
