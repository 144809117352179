import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthenticatedLayout from "../components/layouts/Authenticated.layout";
import HomePage from "../pages/Home.page";
import RegistrationsPage from "../pages/Registrations.page";
import CongressPageLayout from "../components/layouts/CongressPage.layout";
import PreviewPage from "../pages/Preview.page";
import { ProtectedRoute } from "../components/common/ProtectedRoute";
import SuccessRegistrationPage from "../pages/SuccessRegistration.page";
import ParticipantSummaryPage from "../pages/ParticipantSummary.page";
import PresentationSummaryPage from "../pages/PresentationSummary.page";

const LoginPage = lazy(() => import("../pages/Login.page"));
const NotFoundPage = lazy(() => import("../pages/NotFound.page"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<></>}>
        <LoginPage />
      </Suspense>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: "/congress/:congressId",
    element: <PreviewPage />,
  },
  {
    path: "preview/:congressId",
    element: (
      <ProtectedRoute>
        <PreviewPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/success-registration/:registrationId",
    element: <SuccessRegistrationPage />,
  },
  {
    path: "/notfound/:locale/:status",
    element: (
      <Suspense fallback={<></>}>
        <NotFoundPage />
      </Suspense>
    ),
  },
  {
    path: "/authenticated",
    element: (
      <ProtectedRoute>
        <AuthenticatedLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "home",
        element: <HomePage />,
      },
      {
        path: "registrations/:congressId",
        element: <RegistrationsPage />,
      },
      {
        path: "participant/:participantId",
        element: <ParticipantSummaryPage />,
      },
      {
        path: "presentation/:participantId",
        element: <PresentationSummaryPage />,
      },
      {
        path: "congress/:congressId",
        element: <CongressPageLayout />,
        async lazy() {
          let { CongressPageLayout } = await import(
            "../components/layouts/CongressPage.layout"
          );
          return { Component: CongressPageLayout };
        },
        children: [
          {
            path: "module-settings",
            async lazy() {
              let { CongressModuleSettingsPage } = await import(
                "../pages/CongressModuleSettings.page"
              );
              return { Component: CongressModuleSettingsPage };
            },
          },
          {
            path: "additional-fields",
            async lazy() {
              let { AdditionalFieldsPage } = await import(
                "../pages/AdditionalFields.page"
              );
              return { Component: AdditionalFieldsPage };
            },
          },
          {
            path: "presentations",
            async lazy() {
              let { PresentationsPage } = await import(
                "../pages/Presentations.page"
              );
              return { Component: PresentationsPage };
            },
          },
          {
            path: "fees",
            async lazy() {
              let { FeesPage } = await import("../pages/Fees.page");
              return { Component: FeesPage };
            },
          },
          {
            path: "activities",
            async lazy() {
              let { ActivitiesPage } = await import("../pages/Activities.page");
              return { Component: ActivitiesPage };
            },
          },
          {
            path: "meals",
            async lazy() {
              let { MealsPage } = await import("../pages/Meals.page");
              return { Component: MealsPage };
            },
          },
          {
            path: "accomodations",
            async lazy() {
              let { AccomodationsPage } = await import(
                "../pages/Accomodations.page"
              );
              return { Component: AccomodationsPage };
            },
          },
          {
            path: "other-informations",
            async lazy() {
              let { OtherInformationPage } = await import(
                "../pages/OtherInfo.page"
              );
              return { Component: OtherInformationPage };
            },
          },
        ],
      },
    ],
  },
]);
