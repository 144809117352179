import React from "react";
import { useListActiveMailingFieldsQuery } from "../../store/additional-fields/additional-fields";
import { CustomFieldForm } from "./CustomFieldForm";

interface CustomMailingRegistrationProps {
  congressId: number;
  fieldValues: Map<number, number | string>;
  setFieldValue: Function;
}

export const CustomMailingRegistration: React.FC<
  CustomMailingRegistrationProps
> = ({ congressId, fieldValues, setFieldValue }) => {
  const { data: fields = [] } = useListActiveMailingFieldsQuery(congressId);

  return (
    <CustomFieldForm
      fields={fields}
      fieldValues={fieldValues}
      setFieldValue={setFieldValue}
      prefix="address"
    />
  );
};
