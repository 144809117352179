import { createApi } from "@reduxjs/toolkit/query/react";
import { Accomodation, Room } from "../../types/accomodation.types";
import axiosBaseQuery from "../../utils/axios-query";
import { EnableRequest, UpdateOrdinal } from "../../types/activity.types";

export const accomodationApi = createApi({
  reducerPath: "accomodationApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Accomodations", "Room", "PublicAccomodation", "PublicRoom"],
  endpoints: (builder) => ({
    listAccomodations: builder.query<Accomodation[], number | string>({
      query: (congressId: number) => ({
        url: `hotel/${congressId}`,
      }),
      providesTags: ["Accomodations"],
    }),
    listActiveAccomodations: builder.query<Accomodation[], number | string>({
      query: (congressId: number) => ({
        url: `hotel/${congressId}/active/public`,
      }),
      providesTags: ["PublicAccomodation"],
    }),
    createAccomodation: builder.mutation<Accomodation, Accomodation>({
      query: (accomodation: Accomodation) => ({
        url: `hotel/`,
        method: "POST",
        data: accomodation,
      }),
      invalidatesTags: ["Accomodations", "PublicAccomodation"],
    }),
    updateAccomodation: builder.mutation<Accomodation, Accomodation>({
      query: (accomodation: Accomodation) => ({
        url: `hotel/${accomodation.id}`,
        method: "PUT",
        data: accomodation,
      }),
      invalidatesTags: ["Accomodations", "PublicAccomodation"],
    }),
    activateAccomodation: builder.mutation<Accomodation, EnableRequest>({
      query: (accomodation: EnableRequest) => ({
        url: `hotel/${accomodation.id}/enabled`,
        method: "PUT",
        data: accomodation,
      }),
      invalidatesTags: ["Accomodations", "PublicAccomodation"],
    }),
    updateAccomodationOrdinal: builder.mutation<Accomodation, UpdateOrdinal>({
      query: (accomodation: UpdateOrdinal) => ({
        url: `hotel/${accomodation.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: accomodation.newOrdinal },
      }),
      invalidatesTags: ["Accomodations", "PublicAccomodation"],
    }),
    listRoomsByAccomodationId: builder.query<
      Room[],
      { accomodationId: number | string; congressId: number | string }
    >({
      query: (ids) => ({
        url: `hotel-room/${ids.accomodationId}?congress-id=${ids.congressId}`,
      }),
      providesTags: ["Room"],
    }),
    listActiveRoomsByAccomodationId: builder.query<
      Room[],
      { accomodationId: number | string; congressId: number | string }
    >({
      query: (ids) => ({
        url: `hotel-room/${ids.accomodationId}/active/public?congress-id=${ids.congressId}`,
      }),
      providesTags: ["PublicRoom"],
    }),
    createRoom: builder.mutation<Room, Room>({
      query: (room: Room) => ({
        url: `hotel-room/`,
        method: "POST",
        data: room,
      }),
      invalidatesTags: ["Room", "PublicRoom", "Accomodations"],
    }),
    updateRoom: builder.mutation<Room, Room>({
      query: (room: Room) => ({
        url: `hotel-room/${room.id}`,
        method: "PUT",
        data: room,
      }),
      invalidatesTags: ["Room", "PublicRoom", "Accomodations"],
    }),
    activateRoom: builder.mutation<Room, EnableRequest>({
      query: (room: EnableRequest) => ({
        url: `hotel-room/${room.id}/enabled`,
        method: "PUT",
        data: room,
      }),
      invalidatesTags: ["Room", "PublicRoom", "Accomodations"],
    }),
    updateRoomOrdinal: builder.mutation<Room, UpdateOrdinal>({
      query: (room: UpdateOrdinal) => ({
        url: `hotel-room/${room.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: room.newOrdinal },
      }),
      invalidatesTags: ["Room", "PublicRoom", "Accomodations"],
    }),
  }),
});

export const {
  useListAccomodationsQuery,
  useCreateAccomodationMutation,
  useUpdateAccomodationMutation,
  useActivateAccomodationMutation,
  useUpdateAccomodationOrdinalMutation,
  useListRoomsByAccomodationIdQuery,
  useCreateRoomMutation,
  useUpdateRoomMutation,
  useActivateRoomMutation,
  useUpdateRoomOrdinalMutation,
  useListActiveAccomodationsQuery,
} = accomodationApi;
