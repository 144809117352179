import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axios-query";
import { AdditionalText } from "../../types/additional.types";

export const additionalTextApi = createApi({
  reducerPath: "additionalTextApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["AdditionalText"],
  endpoints: (builder) => ({
    getTextByCongressId: builder.query<AdditionalText, number | string>({
      query: (congressId: number) => ({
        url: `additional-text/${congressId}/public`,
      }),
      providesTags: ["AdditionalText"],
    }),
    createText: builder.mutation<AdditionalText, AdditionalText>({
      query: (text: AdditionalText) => ({
        url: `additional-text/`,
        method: "POST",
        data: text,
      }),
      invalidatesTags: ["AdditionalText"],
    }),
    updateText: builder.mutation<void, AdditionalText>({
      query: (text: AdditionalText) => ({
        url: `additional-text/${text.congressEventId}`,
        method: "PUT",
        data: text,
      }),
      invalidatesTags: ["AdditionalText"],
    }),
  }),
});

export const {
  useGetTextByCongressIdQuery,
  useCreateTextMutation,
  useUpdateTextMutation,
} = additionalTextApi;
