import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axios-query";
import { Login, Token } from "../../types/user.types";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<Token, Login>({
      query: (login: Login) => ({
        url: `login`,
        method: "POST",
        data: login,
      }),
    }),
  }),
});

export const { useLoginMutation } = userApi;
