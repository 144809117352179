import {
  Heading,
  Stack,
  List,
  ListItem,
  ListIcon,
  HStack,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import CurrencyContext from "../../context/CurrencyContext";
import { Trans } from "@lingui/macro";
import { AccomodationSummary as AccomodationSummaryType } from "../../types/accomodation.types";
import { formatDateForInput } from "../../utils/date-formatter";
import { FaHotel, FaBed, FaRegCalendarAlt, FaMoon } from "react-icons/fa";
import { formatPrice } from "../../utils/price-formatter";

interface AccomodationSummaryProps {
  accomodationSummary: AccomodationSummaryType | null;
}

export const AccomodationSummary: React.FC<AccomodationSummaryProps> = ({
  accomodationSummary,
}) => {
  const currency = useContext(CurrencyContext);
  return (
    <Stack
      px={0}
      py={3}
      justifyContent={{
        base: "flex-start",
        md: "space-between",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      alignItems={{ md: "center" }}
    >
      {accomodationSummary && (
        <>
          <Heading size={"md"} w={{ md: "34%" }}>
            {accomodationSummary.hotelName}
          </Heading>
          <List spacing={3} textAlign="start" w={{ md: "40%" }}>
            <ListItem alignItems={"baseline"}>
              {" "}
              <HStack gap={0}>
                <ListIcon as={FaHotel} color="green.500" />
                <Text>{accomodationSummary.hotelRoomName}</Text>
              </HStack>
            </ListItem>
            <ListItem alignItems={"baseline"}>
              {" "}
              <HStack gap={0}>
                <ListIcon as={FaMoon} color="green.500" />
                <Text>
                  {formatPrice(accomodationSummary.price)} {currency} /{" "}
                  <Trans>night</Trans>
                </Text>
              </HStack>
            </ListItem>
            <ListItem alignItems={"baseline"}>
              {" "}
              <HStack gap={0}>
                <ListIcon as={FaRegCalendarAlt} color="green.500" />
                <Text whiteSpace={"nowrap"}>
                  {formatDateForInput(accomodationSummary.arrivalDate)} -{" "}
                  {formatDateForInput(accomodationSummary.departureDate)} (
                  {accomodationSummary.numberOfNights} <Trans>nights</Trans>)
                </Text>
              </HStack>
            </ListItem>
            <ListItem alignItems={"baseline"}>
              {" "}
              <HStack gap={0}>
                <ListIcon as={FaBed} color="green.500" />
                <Text>
                  <Trans>Name of roommate(s)</Trans>:{" "}
                  {accomodationSummary.roomMateName || "-"}
                </Text>
              </HStack>
            </ListItem>
          </List>
          <Heading size={"xl"} w={{ md: "24%" }} textAlign={{ md: "end" }}>
            {formatPrice(
              accomodationSummary.price * accomodationSummary.numberOfNights
            )}{" "}
            {currency}
          </Heading>
        </>
      )}
      {!accomodationSummary && (
        <>
          <Text w={{ md: "39%" }}>
            <Trans>Not ordered</Trans>
          </Text>
          <Stack w={{ md: "40%" }} />
          <Heading size={"xl"} w={{ md: "19%" }} textAlign={{ md: "end" }}>
            0 {currency}
          </Heading>
        </>
      )}
    </Stack>
  );
};
