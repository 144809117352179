import { AccomodationSummary } from "./accomodation.types";
import { ActivitySummary } from "./activity.types";
import { AddressSummary, PaymentSummary } from "./address.types";
import { Conference } from "./conference.types";
import { ParticipantFeeSummary } from "./fee.types";
import { PresentationSummary } from "./presentation.types";

export enum ExcelDownloadType {
  DOWNLOADED = "DOWNLOADED",
  NOT_DOWNLOADED = "NOT_DOWNLOADED",
}

export enum ParticipantTitleType {
  DR = "DR",
  PROF = "PROF",
  DR_PROF = "DR_PROF",
  NONE = "NONE",
}

export enum KeepPresentationType {
  YES = "YES",
  NO = "NO",
}

export enum PaymentType {
  TRANSFER = "TRANSFER",
  CARD = "CARD",
  OTHER = "OTHER",
}

export interface ParticipantFeeSummaryResponse {
  id: number;
  totalPrice: number;
}

export interface Participant {
  id: number;
  congressEventId: number;
  downloadStatusType: ExcelDownloadType;
  firstName: string;
  lastName: string;
  feeSummaryResponse: ParticipantFeeSummaryResponse;
  registrationId: number;
  keepPresentation: KeepPresentationType;
  title: ParticipantTitleType;
  category: string;
}

interface ParticipantRequestBase {
  title: ParticipantTitleType;
  firstName: string;
  surName: string;
  workplace: string;
  email: string;
}

export interface ParticipantRequest extends ParticipantRequestBase {
  valuesByFieldId: Map<number, string | number>;
}

export interface ParticipantSummary extends ParticipantRequestBase {}

export interface ParticipantPreview {
  id: number;
  congressEventId: number;
  surname: string;
  lastname: string;
  title: ParticipantTitleType;
  sealNumber: string;
  job: string;
  workplace: string;
  laddressId: number;
}

export interface ParticipantFullSummary {
  id: number;
  registrationNumber: number;
  category: string;
  title: ParticipantTitleType;
  fullName: string;
  sealNumber: string;
  position: string;
  workplace: string;
  className: string;
  umberOfAttendants: number;
  attendantsName: string;
  holdingPresentation: boolean;
  address: AddressSummary;
  payment: PaymentSummary;
  meals: ActivitySummary[] | null;
  professionals: ActivitySummary[] | null;
  socials: ActivitySummary[] | null;
  hotelBooking: AccomodationSummary | null;
  presentations: PresentationSummary[] | null;
  participationFee: ParticipantFeeSummary | null;
  escortFee: ParticipantFeeSummary | null;
  congressEvent: Conference;
  comment: string;
  customFieldValues: Record<string, string>;
  totalPrice: number | null;
  email: string;
}
