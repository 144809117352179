import React from "react";
import { HStack, Skeleton, SkeletonText, Stack, Text } from "@chakra-ui/react";
import { DynamicFormStack } from "../common/ResponsiveFormStack";
import { Trans } from "@lingui/macro";
import { useListActiveMealsForCongressIdQuery } from "../../store/activity/activity";
import { PreviewDropdownFormControl } from "../common/FormControlComponents";
import { useLingui } from "@lingui/react";
import { ActivityChoice } from "../../types/activity.types";
import CurrencyContext from "../../context/CurrencyContext";
import { formatDateForInput } from "../../utils/date-formatter";
import { formatPrice } from "../../utils/price-formatter";

interface MealsProps {
  meals: ActivityChoice[];
  congressId: number;
  setFieldValue: Function;
}

const Meals: React.FC<MealsProps> = ({ congressId, setFieldValue, meals }) => {
  const { i18n } = useLingui();
  const currency = React.useContext(CurrencyContext);
  const { data: mealList = [], isLoading: areMealsLoading } =
    useListActiveMealsForCongressIdQuery(congressId);

  React.useEffect(() => {
    const activityChoiceList: ActivityChoice[] = mealList.map((meal) => ({
      activityId: meal.id,
      count: 0,
    }));
    setFieldValue(`meals`, activityChoiceList);
  }, [mealList, setFieldValue]);

  return (
    <Stack data-testid="preview-meals-form">
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Meals</Trans>
        </Text>
      </HStack>
      <DynamicFormStack>
        {mealList.map((meal, index) => (
          <PreviewDropdownFormControl
            key={index}
            id={`meal${index}`}
            label={`${meal.name} ${formatDateForInput(meal.date)}`}
            values={[
              { id: 0, leftText: i18n._("Not ordered") },
              {
                id: 1,
                leftText: i18n._("1 person"),
                rightText: `${formatPrice(meal.price)} ${currency}`,
              },
              {
                id: 2,
                leftText: i18n._("2 person"),
                rightText: `${formatPrice(meal.price * 2)} ${currency}`,
              },
              {
                id: 3,
                leftText: i18n._("3 person"),
                rightText: `${formatPrice(meal.price * 3)} ${currency}`,
              },
            ]}
            fieldName={`meals[${index}].count`}
            setFieldValue={setFieldValue}
            selectedId={meals[index]?.count}
            testId={`meals-dropdown-${meal.name}`}
          />
        ))}
      </DynamicFormStack>
      {areMealsLoading && (
        <>
          <Skeleton height="50px" />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
          <Skeleton height="50px" />
        </>
      )}
    </Stack>
  );
};

export default Meals;
