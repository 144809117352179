import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";
import Dropdown, { DropdownProps } from "./Dropdown";
import PreviewDropdown, { PreviewDropdownProps } from "./PreviewDropdown";

interface InputFormControlProps {
  id: string;
  label: string;
  inputType?: React.HTMLInputTypeAttribute;
  isRequired?: boolean;
  value?: string | number;
  readOnly?: boolean;
  name?: string;
  onChangeValue?: Function;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  min?: string | number;
  max?: string;
  pattern?: string;
  error?: string | boolean;
  testId?: string;
}

export const InputFormControl: React.FC<InputFormControlProps> = ({
  id,
  label,
  inputType = "text",
  isRequired = false,
  value,
  readOnly = false,
  name = "",
  onChangeValue = () => {},
  onChange = () => {},
  onBlur = () => {},
  min,
  max,
  error,
  testId,
}) => {
  const onChangeCallback = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue(event.target.value);
      onChange(event);
    },
    [onChange, onChangeValue]
  );
  return (
    <FormControl id={id} isRequired={isRequired} isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        type={inputType}
        value={value}
        readOnly={readOnly}
        name={name}
        onBlur={onBlur}
        onChange={onChangeCallback}
        min={min}
        max={max}
        data-testid={testId}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

interface DropdownFormControlProps extends DropdownProps {
  id: string;
  label: string;
  isRequired?: boolean;
}

export const DropdownFormControl: React.FC<DropdownFormControlProps> = ({
  id,
  label,
  isRequired = false,
  ...props
}) => (
  <FormControl id={id} isRequired={isRequired}>
    <FormLabel>{label}</FormLabel>
    <Dropdown {...props} />
  </FormControl>
);

interface PreviewDropdownFormControlProps extends PreviewDropdownProps {
  id: string;
  label: string;
  isRequired?: boolean;
}

export const PreviewDropdownFormControl: React.FC<
  PreviewDropdownFormControlProps
> = ({ id, label, isRequired = false, ...props }) => {
  return (
    <FormControl id={id} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <PreviewDropdown {...props} />
    </FormControl>
  );
};
