import React from "react";
import { Box, HStack, Stack, Text, Textarea } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";

interface CommentProps {
  value: string;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const Comment: React.FC<CommentProps> = ({ value, handleChange }) => (
  <Stack>
    <HStack>
      <Text fontSize="xl" mb="2">
        <Trans>Comment</Trans>
      </Text>
    </HStack>
    <HStack>
      <Box w="100%">
        <Textarea
          size="3xl"
          borderRadius={"8px"}
          value={value}
          onChange={handleChange}
          name="comment"
          data-testid="comment-textarea"
        />
      </Box>
    </HStack>
  </Stack>
);

export default Comment;
