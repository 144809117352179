import {
  KeepPresentationType,
  ParticipantTitleType,
} from "../types/participant.types";

const PARTICIPANT_TITLES = {
  DR: "Dr.",
  PROF: "Prof.",
  DR_PROF: "Dr. Prof.",
  NONE: "",
};

export const mapParticipantTitle = (titleKey: ParticipantTitleType): string =>
  PARTICIPANT_TITLES[titleKey] || "";

export const mapKeepPresentation = (key: KeepPresentationType): string =>
  key === KeepPresentationType.YES ? "Yes" : "No";
