import React from "react";
import { Text } from "@chakra-ui/react";

interface BadgeProps {
  children?: React.ReactElement | React.ReactElement[] | string;
  color?: string;
}

export const Badge: React.FC<BadgeProps> = ({ children, color = "blue" }) => (
  <Text
    textTransform={"uppercase"}
    color={color + ".400"}
    fontWeight={600}
    fontSize={"sm"}
    bg={color + ".50"}
    p={2}
    alignSelf={"flex-start"}
    rounded={"md"}
  >
    {children}
  </Text>
);
