import React from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import ResponsiveFormStack from "../common/ResponsiveFormStack";
import {
  InputFormControl,
  PreviewDropdownFormControl,
} from "../common/FormControlComponents";
import { GreenButton } from "../common/CongressButton";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { PaymentType } from "../../types/participant.types";
import { CustomBillingRegistration } from "./CustomBillingRegistration";

interface BillingAddressProps {
  payment: PaymentType | undefined;
  onCopy: Function;
  fullName: string;
  city: string;
  street: string;
  tax: string;
  phone: string;
  email: string;
  country: string;
  postalCode: string;
  handleChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  setFieldValue: Function;
  congressId: number;
  fieldValues: Map<number, number | string>;
  isCopyAvailable: boolean;
}

const BillingAddress: React.FC<BillingAddressProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <Stack>
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Billing address</Trans>
        </Text>
      </HStack>
      <ResponsiveFormStack
        firstElement={
          <PreviewDropdownFormControl
            id={"payment"}
            label={i18n._("Payment")}
            values={[
              {
                id: PaymentType.TRANSFER,
                leftText: i18n._("For myself by bank transfer"),
              },
              {
                id: PaymentType.CARD,
                leftText: i18n._("Debit or Credit card"),
              },
              {
                id: PaymentType.OTHER,
                leftText: i18n._("Workspace, sponsor by bank transfer"),
              },
            ]}
            fieldName="payment.payment"
            setFieldValue={props.setFieldValue}
            selectedId={props.payment}
            isRequired
            defaultPreviewText={i18n._("Please select!")}
            testId="billing-payment-dropdown"
          />
        }
      />
      {props.isCopyAvailable && (
        <Stack mb="4">
          <Text fontSize="m">
            <Trans>Copy participant data:</Trans>
          </Text>
          <GreenButton
            width="fit-content"
            onClick={props.onCopy}
            testId="billing-copy-participant-data"
          >
            <Trans>Copy</Trans>
          </GreenButton>
        </Stack>
      )}
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="billingFullName"
            label={i18n._("Billing name")}
            inputType="text"
            name="payment.fullName"
            value={props.fullName}
            onChange={props.handleChange}
            isRequired
            testId="billing-full-name-input"
          />
        }
        secondElement={
          <InputFormControl
            id="billingTaxNumber"
            label={i18n._("VAT ID number")}
            inputType="text"
            name="payment.taxNumber"
            value={props.tax}
            onChange={props.handleChange}
            testId="billing-tax-number-input"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="billingCountry"
            label={i18n._("Country")}
            inputType="text"
            name="payment.country"
            value={props.country}
            onChange={props.handleChange}
            isRequired
            testId="billing-country-input"
          />
        }
        secondElement={
          <InputFormControl
            id="billingPostalCode"
            label={i18n._("Postal code")}
            inputType="text"
            name="payment.postalCode"
            value={props.postalCode}
            onChange={props.handleChange}
            isRequired
            testId="billing-postal-code-input"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="billingCity"
            label={i18n._("City")}
            inputType="text"
            name="payment.city"
            value={props.city}
            onChange={props.handleChange}
            isRequired
            testId="billing-city-input"
          />
        }
        secondElement={
          <InputFormControl
            id="billingAddress"
            label={i18n._("Address")}
            inputType="text"
            name="payment.street"
            value={props.street}
            onChange={props.handleChange}
            isRequired
            testId="billing-address-input"
          />
        }
      />
      <ResponsiveFormStack
        firstElement={
          <InputFormControl
            id="billingEmail"
            label={i18n._("E-mail")}
            inputType="email"
            name="payment.email"
            value={props.email}
            onChange={props.handleChange}
            isRequired
            testId="billing-email-input"
          />
        }
        secondElement={
          <InputFormControl
            id="billingMobile"
            label={i18n._("Mobile")}
            inputType="tel"
            name="payment.phone"
            value={props.phone}
            onChange={props.handleChange}
            testId="billing-mobile-input"
          />
        }
      />
      <CustomBillingRegistration
        congressId={props.congressId}
        fieldValues={props.fieldValues}
        setFieldValue={props.setFieldValue}
      />
      <HStack>
        <Text fontSize="m" mt="4">
          <Trans>
            In case other parties pay a portion of your fees, we kindly ask you
            to write paying details (exact billing information) into the comment
            field at the end of the page.
          </Trans>
        </Text>
      </HStack>
    </Stack>
  );
};

export default BillingAddress;
