export enum ActivityType {
  MEAL = "MEAL",
  SOCIAL = "SOCIAL",
  PROFESSIONAL = "PROFESSIONAL",
  INFO = "INFO",
}

export interface Activity {
  id: number;
  ordinal: number;
  name: string;
  congressEventId: number;
  activityType: ActivityType;
  date: Date | string;
  price: number;
  enabled: boolean;
  textBox?: string;
}

export interface EnableRequest {
  id: string | number;
  enabled: boolean;
}

export interface UpdateOrdinal {
  id: number | string;
  newOrdinal: number;
}

export const createEmptyActivity = (
  congressId: string | undefined,
  activity: ActivityType,
  defaultDate?: string
): Activity => ({
  id: 0,
  ordinal: 0,
  name: "",
  congressEventId: parseInt(congressId || ""),
  activityType: activity,
  date: defaultDate || "",
  price: undefined as unknown as number,
  enabled: true,
  textBox: "",
});

export interface ActivityChoice {
  activityId: number;
  count: number;
}

export interface ActivitySummary {
  activityId: number;
  name: string;
  price: number;
  activityType: ActivityType;
  choiceCount: number;
  date: Date | string;
}
