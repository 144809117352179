import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios from "axios";
import type { AxiosRequestConfig, AxiosError } from "axios";
import { getUserToken, removeUserToken } from "./storage-handlers";
import { isInsideIframe } from "./iframe-utils";

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = {
      baseUrl: process.env.REACT_APP_BASE_URL || "",
    }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
      responseType?: AxiosRequestConfig["responseType"];
    },
    unknown,
    unknown
  > =>
  async ({
    url,
    method = "GET",
    data,
    params,
    headers = {},
    responseType = undefined,
  }) => {
    try {
      const userToken = getUserToken();
      let authHeaders = userToken
        ? { Authorization: `Bearer ${userToken}`, ...headers }
        : headers;
      // if (process.env.REACT_APP_ALLOW_ORIGIN) {
      //   authHeaders = {
      //     ...authHeaders,
      //     "Access-Control-Allow-Origin": process.env.REACT_APP_ALLOW_ORIGIN,
      //   };
      // }
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: authHeaders,
        responseType,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      if (err.response?.status === 401) {
        removeUserToken();
        if (
          window.location.href.includes("/authenticated/") &&
          !isInsideIframe()
        ) {
          window.location.href = "/";
        } else {
          window.location.href = "/notfound/hu/notfound";
        }
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
