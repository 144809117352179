import { Box, Heading, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { WarningTwoIcon, InfoIcon } from "@chakra-ui/icons";
import { useGetHeightAndSendMessage } from "../utils/useGetHeightAndSendMessage";

export enum NotFoundType {
  Unavailable = "unavailable",
  Closed = "closed",
  NotFound = "notfound",
}

const NotFoundPage = () => {
  const { status, locale } = useParams();
  const { i18n } = useLingui();
  const pageRef = useGetHeightAndSendMessage();
  const message = React.useMemo(() => {
    switch (status) {
      case NotFoundType.Unavailable:
        return i18n._(
          `Right now, the registration isn't available for this event. Please try it later!`
        );
      case NotFoundType.Closed:
        return i18n._(
          `Registration to this event is closed. You can't apply for it!`
        );
      default:
        return i18n._(`We didn't find what you're looking for!`);
    }
  }, [i18n, status]);

  const icon = React.useMemo(() => {
    switch (status) {
      case NotFoundType.Unavailable:
        return <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />;
      case NotFoundType.Closed:
        return <InfoIcon boxSize={"50px"} color={"blue.500"} />;
      default:
        return (
          <Heading
            display="inline-block"
            as="h2"
            size="2xl"
            bgGradient="linear(to-r, teal.400, teal.600)"
            backgroundClip="text"
          >
            404
          </Heading>
        );
    }
  }, [status]);

  React.useEffect(() => {
    if (locale && ["hu", "en"].includes(locale)) {
      i18n.activate(locale);
    } else {
      i18n.activate("hu");
    }
  }, [locale, i18n]);

  return (
    <Box textAlign="center" py={10} px={6} ref={pageRef}>
      {icon}
      <Heading as="h2" size="xl" mt={6} mb={2}>
        <Trans>We sorry!</Trans>
      </Heading>
      <Text color={"gray.500"} mb={8}>
        {message}
      </Text>
    </Box>
  );
};

export default NotFoundPage;
