export const formatDateForInput = (
  date: string | number | Date | undefined
): string | undefined =>
  date !== undefined && date !== "" && date !== null
    ? new Date(date).toISOString().split("T")[0]
    : "";

export const getIntervalInDays = (
  startDate: string | number | Date | undefined,
  endDate: string | number | Date | undefined
): number | undefined => {
  if ((startDate === endDate) === undefined) {
    return undefined;
  } else if (startDate === undefined || endDate === undefined) {
    return undefined;
  } else {
    const _startDate = new Date(startDate);
    const _endDate = new Date(endDate);
    const diff = _endDate.getTime() - _startDate.getTime();
    return diff / (1000 * 3600 * 24);
  }
};
