import React from "react";
import {
  Divider,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";
import ResponsiveFormStack from "../common/ResponsiveFormStack";
import {
  InputFormControl,
  PreviewDropdownFormControl,
} from "../common/FormControlComponents";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { useListActiveEscortFeesByCongressIdQuery } from "../../store/fee/fee";
import { EscortFeeRequest } from "../../types/fee.types";
import CurrencyContext from "../../context/CurrencyContext";
import { formatPrice } from "../../utils/price-formatter";

interface EscortFeesProps {
  congressId: number;
  handleChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  setFieldValue: Function;
  fee: EscortFeeRequest;
}

const EscortFees: React.FC<EscortFeesProps> = ({
  congressId,
  setFieldValue,
  handleChange,
  fee,
}) => {
  const { i18n } = useLingui();
  const { data: fees = [], isLoading: areFeesLoading } =
    useListActiveEscortFeesByCongressIdQuery(congressId);
  const currency = React.useContext(CurrencyContext);
  const escortFees = React.useMemo(
    () =>
      fees.length > 0
        ? [
            {
              id: 0,
              leftText: i18n._("Not ordered"),
            },
            {
              id: 1,
              leftText: i18n._("1 accompanying person"),
              rightText: `${formatPrice(fees[0].totalPrice)} ${currency}`,
            },
            {
              id: 2,
              leftText: i18n._("2 accompanying person"),
              rightText: `${formatPrice(fees[0].totalPrice * 2)} ${currency}`,
            },
            {
              id: 3,
              leftText: i18n._("3 accompanying person"),
              rightText: `${formatPrice(fees[0].totalPrice * 3)} ${currency}`,
            },
          ]
        : [],
    [currency, fees, i18n, (i18n as any)._locale]
  );

  React.useEffect(() => {
    fees.length && setFieldValue("escortFee.participationFeeId", fees[0].id);
  }, [fees, setFieldValue]);

  return (
    <>
      <Stack>
        {fees.length > 0 && (
          <>
            <HStack>
              <Text fontSize="xl" mb="2">
                <Trans>Escort Fee</Trans>
              </Text>
            </HStack>
            <ResponsiveFormStack
              firstElement={
                <PreviewDropdownFormControl
                  id={"escortFees"}
                  label={i18n._("Escort Fees")}
                  values={escortFees}
                  fieldName="escortFee.numberOfAttendants"
                  setFieldValue={setFieldValue}
                  selectedId={fee.numberOfAttendants}
                  testId="escort-fees-dropdown"
                />
              }
              secondElement={
                fee.numberOfAttendants > 0 ? (
                  <InputFormControl
                    id="attendantsName"
                    label={i18n._("Name of accompanying person(s)")}
                    inputType="text"
                    value={fee.attendantsName}
                    name="escortFee.attendantsName"
                    onChange={handleChange}
                    isRequired
                    testId="attendants-name-input"
                  />
                ) : (
                  <></>
                )
              }
            />
          </>
        )}
        {areFeesLoading && (
          <>
            <Skeleton height="50px" />
            <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
            <Skeleton height="50px" />
          </>
        )}
      </Stack>
      {fees.length > 0 && <Divider my={{ base: 4 }} />}
    </>
  );
};

export default EscortFees;
