import React from "react";
import { Heading as ChackraHeading } from "@chakra-ui/react";

interface HeadingProps {
  children?: React.ReactNode | React.ReactNode[];
}

const Heading: React.FC<HeadingProps> = ({ children }) => (
  <ChackraHeading
    color={"gray.800"}
    lineHeight={1.1}
    fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
    textAlign={"center"}
  >
    {children}
  </ChackraHeading>
);

export default Heading;
