import {
  Heading,
  Stack,
  List,
  ListItem,
  ListIcon,
  HStack,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import CurrencyContext from "../../context/CurrencyContext";
import { Trans } from "@lingui/macro";
import { FaCcMastercard, FaInfo } from "react-icons/fa";
import { ActivitySummary as ActivitySummaryType } from "../../types/activity.types";
import { MultiUserMessage } from "../common/MultiUserMessage";
import { formatDateForInput } from "../../utils/date-formatter";
import { formatPrice } from "../../utils/price-formatter";

interface ActivitySummaryProps {
  activitySummaries: ActivitySummaryType[];
  color: string;
}

export const ActivitySummary: React.FC<ActivitySummaryProps> = ({
  activitySummaries,
  color,
}) => {
  const currency = useContext(CurrencyContext);
  return (
    <>
      {activitySummaries.map((activity) => (
        <Stack
          px={0}
          py={3}
          justifyContent={{
            base: "flex-start",
            md: "space-between",
          }}
          direction={{
            base: "column",
            md: "row",
          }}
          alignItems={{ md: "center" }}
          key={activity.activityType + activity.activityId}
        >
          <Heading size={"md"} w={{ md: "34%" }}>
            {activity.name}{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {formatDateForInput(activity.date)}
            </span>
          </Heading>
          <List spacing={3} textAlign="start" w={{ md: "40%" }}>
            <ListItem alignItems={"baseline"}>
              <HStack gap={0}>
                <ListIcon as={FaCcMastercard} color={`${color}.500`} />
                <Text>
                  {formatPrice(activity.price)} {currency} /{" "}
                  <Trans>person</Trans>
                </Text>
              </HStack>
            </ListItem>
            <ListItem alignItems={"baseline"}>
              <HStack gap={0}>
                <ListIcon as={FaInfo} color={`${color}.500`} />
                <Text>
                  <MultiUserMessage count={activity.choiceCount} />
                </Text>
              </HStack>
            </ListItem>
          </List>
          <Heading size={"xl"} w={{ md: "40%" }} textAlign={{ md: "end" }}>
            {formatPrice(activity.price * activity.choiceCount)} {currency}
          </Heading>
        </Stack>
      ))}
    </>
  );
};
