import { Trans } from "@lingui/macro";
import React from "react";

interface MultiUserMessageProps {
  count: number;
}

export const MultiUserMessage: React.FC<MultiUserMessageProps> = ({
  count,
}) => {
  switch (count) {
    case 1:
      return <Trans>1 person</Trans>;
    case 2:
      return <Trans>2 person</Trans>;
    case 3:
      return <Trans>3 person</Trans>;
    case 0:
    default:
      return <Trans>Not ordered</Trans>;
  }
};

export const MultiUserMessageEscort: React.FC<MultiUserMessageProps> = ({
  count,
}) => {
  switch (count) {
    case 1:
      return <Trans>1 accompanying person</Trans>;
    case 2:
      return <Trans>2 accompanying person</Trans>;
    case 3:
      return <Trans>3 accompanying person</Trans>;
    case 0:
    default:
      return <Trans>Not ordered</Trans>;
  }
};
