import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface UserState {
  name: string | null
  isLogedin: boolean
}

const initialState: UserState = {
  name: null,
  isLogedin: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{name: string | null}>) => {
      state.name = action.payload.name
      state.isLogedin = true
    },
    logout: (state) => {
        state.name = null
        state.isLogedin = false
    }
  },
})

export const { login, logout } = userSlice.actions

export const selectUserName = (state: RootState) => state.user.name
export const selectUserLogIn = (state: RootState) => state.user.isLogedin

export default userSlice.reducer