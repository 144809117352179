import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axios-query";
import { EnableRequest, UpdateOrdinal } from "../../types/activity.types";
import { Presentation, PresentationType } from "../../types/presentation.types";
import { PreviewDropdownItem } from "../../components/common/PreviewDropdown";

export const presentationApi = createApi({
  reducerPath: "presentationApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "FORM",
    "TOPIC",
    "INFO",
    "PublicFORM",
    "PublicTOPIC",
    "PublicINFO",
  ],
  endpoints: (builder) => ({
    listPresentationFormByCongressId: builder.query<
      Presentation[],
      number | string
    >({
      query: (congressId: number) => ({
        url: `presentation/${congressId}/${PresentationType.FORM}`,
      }),
      providesTags: ["FORM"],
    }),
    listActivePresentationFormByCongressId: builder.query<
      PreviewDropdownItem[],
      number | string
    >({
      query: (congressId: number) => ({
        url: `presentation/${congressId}/${PresentationType.FORM}/enabled/public`,
      }),
      providesTags: ["PublicFORM"],
      transformResponse: (response: Presentation[]) =>
        response.map((presentation) => ({
          id: presentation.id,
          leftText: presentation.name,
        })),
    }),
    createPresentationForm: builder.mutation<Presentation, Presentation>({
      query: (presentation: Presentation) => ({
        url: `presentation/`,
        method: "POST",
        data: presentation,
      }),
      invalidatesTags: ["FORM", "PublicFORM"],
    }),
    updatePresentationForm: builder.mutation<Presentation, Presentation>({
      query: (presentation: Presentation) => ({
        url: `presentation/${presentation.id}`,
        method: "PUT",
        data: presentation,
      }),
      invalidatesTags: ["FORM", "PublicFORM"],
    }),
    activatePresentationForm: builder.mutation<Presentation, EnableRequest>({
      query: (presentation: EnableRequest) => ({
        url: `presentation/${presentation.id}/enabled`,
        method: "PUT",
        data: presentation,
      }),
      invalidatesTags: ["FORM", "PublicFORM"],
    }),
    updatePresentationFormOrdinal: builder.mutation<
      Presentation,
      UpdateOrdinal
    >({
      query: (presentation: UpdateOrdinal) => ({
        url: `presentation/${presentation.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: presentation.newOrdinal },
      }),
      invalidatesTags: ["FORM", "PublicFORM"],
    }),
    listPresentationTopicByCongressId: builder.query<
      Presentation[],
      number | string
    >({
      query: (congressId: number) => ({
        url: `presentation/${congressId}/${PresentationType.TOPIC}`,
      }),
      providesTags: ["TOPIC"],
    }),
    listActivePresentationTopicByCongressId: builder.query<
      PreviewDropdownItem[],
      number | string
    >({
      query: (congressId: number) => ({
        url: `presentation/${congressId}/${PresentationType.TOPIC}/enabled/public`,
      }),
      providesTags: ["PublicTOPIC"],
      transformResponse: (response: Presentation[]) =>
        response.map((presentation) => ({
          id: presentation.id,
          leftText: presentation.name,
        })),
    }),
    createPresentationTopic: builder.mutation<Presentation, Presentation>({
      query: (presentation: Presentation) => ({
        url: `presentation/`,
        method: "POST",
        data: presentation,
      }),
      invalidatesTags: ["TOPIC", "PublicTOPIC"],
    }),
    updatePresentationTopic: builder.mutation<Presentation, Presentation>({
      query: (presentation: Presentation) => ({
        url: `presentation/${presentation.id}`,
        method: "PUT",
        data: presentation,
      }),
      invalidatesTags: ["TOPIC", "PublicTOPIC"],
    }),
    activatePresentationTopic: builder.mutation<Presentation, EnableRequest>({
      query: (presentation: EnableRequest) => ({
        url: `presentation/${presentation.id}/enabled`,
        method: "PUT",
        data: presentation,
      }),
      invalidatesTags: ["TOPIC", "PublicTOPIC"],
    }),
    updatePresentationTopicOrdinal: builder.mutation<
      Presentation,
      UpdateOrdinal
    >({
      query: (presentation: UpdateOrdinal) => ({
        url: `presentation/${presentation.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: presentation.newOrdinal },
      }),
      invalidatesTags: ["TOPIC", "PublicTOPIC"],
    }),
    listPresentationInfoByCongressId: builder.query<
      Presentation[],
      number | string
    >({
      query: (congressId: number) => ({
        url: `presentation/${congressId}/${PresentationType.EXTRA_INFOS}`,
      }),
      providesTags: ["INFO"],
    }),
    getActivePresentationInfoByCongressId: builder.query<
      Presentation | undefined,
      number | string
    >({
      query: (congressId: number) => ({
        url: `presentation/${congressId}/${PresentationType.EXTRA_INFOS}/enabled/public`,
      }),
      providesTags: ["PublicINFO"],
      transformResponse: (response: Presentation[]) =>
        response.length ? response[0] : undefined,
    }),
    createPresentationInfo: builder.mutation<Presentation, Presentation>({
      query: (presentation: Presentation) => ({
        url: `presentation/`,
        method: "POST",
        data: presentation,
      }),
      invalidatesTags: ["INFO", "PublicINFO"],
    }),
    updatePresentationInfo: builder.mutation<Presentation, Presentation>({
      query: (presentation: Presentation) => ({
        url: `presentation/${presentation.id}`,
        method: "PUT",
        data: presentation,
      }),
      invalidatesTags: ["INFO", "PublicINFO"],
    }),
    activatePresentationInfo: builder.mutation<Presentation, EnableRequest>({
      query: (presentation: EnableRequest) => ({
        url: `presentation/${presentation.id}/enabled`,
        method: "PUT",
        data: presentation,
      }),
      invalidatesTags: ["INFO", "PublicINFO"],
    }),
  }),
});

export const {
  useListPresentationFormByCongressIdQuery,
  useListActivePresentationFormByCongressIdQuery,
  useCreatePresentationFormMutation,
  useUpdatePresentationFormMutation,
  useActivatePresentationFormMutation,
  useUpdatePresentationFormOrdinalMutation,
  useListPresentationTopicByCongressIdQuery,
  useListActivePresentationTopicByCongressIdQuery,
  useCreatePresentationTopicMutation,
  useUpdatePresentationTopicMutation,
  useActivatePresentationTopicMutation,
  useUpdatePresentationTopicOrdinalMutation,
  useListPresentationInfoByCongressIdQuery,
  useGetActivePresentationInfoByCongressIdQuery,
  useCreatePresentationInfoMutation,
  useUpdatePresentationInfoMutation,
  useActivatePresentationInfoMutation,
} = presentationApi;
