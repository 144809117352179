import React from "react";
import { HStack, Skeleton, SkeletonText, Stack, Text } from "@chakra-ui/react";
import ResponsiveFormStack from "../common/ResponsiveFormStack";
import { PreviewDropdownFormControl } from "../common/FormControlComponents";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { useListActiveParticipantFeesByCongressIdQuery } from "../../store/fee/fee";
import { PartecipantFeeRequest } from "../../types/fee.types";
import CurrencyContext from "../../context/CurrencyContext";
import { formatPrice } from "../../utils/price-formatter";

interface AttendanceFeesProps {
  congressId: number;
  handleChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  setFieldValue: Function;
  fee: PartecipantFeeRequest;
}

const AttendanceFees: React.FC<AttendanceFeesProps> = ({
  congressId,
  setFieldValue,
  fee,
}) => {
  const { i18n } = useLingui();
  const { data: fees = [], isLoading: areParticipantFeesLoading } =
    useListActiveParticipantFeesByCongressIdQuery(congressId);
  const currency = React.useContext(CurrencyContext);
  const participantFees = React.useMemo(
    () =>
      fees.map((fee) => ({
        id: fee.id,
        leftText: fee.name,
        rightText: `${formatPrice(fee.totalPrice)} ${currency}`,
      })),
    [fees, currency]
  );

  return (
    <Stack>
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Attendance Fee</Trans>
        </Text>
      </HStack>
      <ResponsiveFormStack
        firstElement={
          <PreviewDropdownFormControl
            id={"attendanceFees"}
            label={i18n._("Attendance Fees")}
            values={participantFees}
            fieldName="participantFee.participationFeeId"
            setFieldValue={setFieldValue}
            isRequired
            selectedId={fee.participationFeeId}
            defaultPreviewText={i18n._("Please select!")}
            testId="attendance-fees-dropdown"
          />
        }
      />
      {areParticipantFeesLoading && (
        <>
          <Skeleton height="50px" />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
          <Skeleton height="50px" />
        </>
      )}
    </Stack>
  );
};

export default AttendanceFees;
