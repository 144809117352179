import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axios-query";
import { EnableRequest, UpdateOrdinal } from "../../types/activity.types";
import { FormField, FormType } from "../../types/additional.types";

export const additionalFieldApi = createApi({
  reducerPath: "additionalFieldApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "AdditionalField",
    "PublicAdditionalField",
    "MailingField",
    "PublicMailingField",
    "BillingField",
    "PublicBillingField",
  ],
  endpoints: (builder) => ({
    listAdditionalFieldsByCongressId: builder.query<FormField[], number>({
      query: (congressId) => ({
        url: `custom-field/${congressId}/${FormType.BASE_DATA}`,
      }),
      providesTags: ["AdditionalField"],
    }),
    listActiveAdditionalFields: builder.query<FormField[], number>({
      query: (congressId) => ({
        url: `custom-field/${congressId}/${FormType.BASE_DATA}/enabled/public`,
      }),
      providesTags: ["AdditionalField", "PublicAdditionalField"],
    }),
    createAdditionalField: builder.mutation<FormField, FormField>({
      query: (field: FormField) => ({
        url: `custom-field/`,
        method: "POST",
        data: field,
      }),
      invalidatesTags: ["AdditionalField", "PublicAdditionalField"],
    }),
    updateAdditionalField: builder.mutation<FormField, FormField>({
      query: (field: FormField) => ({
        url: `custom-field/${field.id}`,
        method: "PUT",
        data: field,
      }),
      invalidatesTags: ["AdditionalField", "PublicAdditionalField"],
    }),
    activateAdditionalField: builder.mutation<FormField, EnableRequest>({
      query: (field: EnableRequest) => ({
        url: `custom-field/${field.id}/enabled`,
        method: "PUT",
        data: field,
      }),
      invalidatesTags: ["AdditionalField", "PublicAdditionalField"],
    }),
    updateAdditionalFieldOrdinal: builder.mutation<FormField, UpdateOrdinal>({
      query: (field: UpdateOrdinal) => ({
        url: `custom-field/${field.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: field.newOrdinal },
      }),
      invalidatesTags: ["AdditionalField", "PublicAdditionalField"],
    }),
    listMailingFieldsByCongressId: builder.query<FormField[], number>({
      query: (congressId) => ({
        url: `custom-field/${congressId}/${FormType.MAILING}`,
      }),
      providesTags: ["MailingField"],
    }),
    listActiveMailingFields: builder.query<FormField[], number>({
      query: (congressId) => ({
        url: `custom-field/${congressId}/${FormType.MAILING}/enabled/public`,
      }),
      providesTags: ["MailingField", "PublicMailingField"],
    }),
    createMailingField: builder.mutation<FormField, FormField>({
      query: (field: FormField) => ({
        url: `custom-field/`,
        method: "POST",
        data: field,
      }),
      invalidatesTags: ["MailingField", "PublicMailingField"],
    }),
    updateMailingField: builder.mutation<FormField, FormField>({
      query: (field: FormField) => ({
        url: `custom-field/${field.id}`,
        method: "PUT",
        data: field,
      }),
      invalidatesTags: ["MailingField", "PublicMailingField"],
    }),
    activateMailingField: builder.mutation<FormField, EnableRequest>({
      query: (field: EnableRequest) => ({
        url: `custom-field/${field.id}/enabled`,
        method: "PUT",
        data: field,
      }),
      invalidatesTags: ["MailingField", "PublicMailingField"],
    }),
    updateMailingFieldOrdinal: builder.mutation<FormField, UpdateOrdinal>({
      query: (field: UpdateOrdinal) => ({
        url: `custom-field/${field.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: field.newOrdinal },
      }),
      invalidatesTags: ["MailingField", "PublicMailingField"],
    }),
    listBillingFieldsByCongressId: builder.query<FormField[], number>({
      query: (congressId) => ({
        url: `custom-field/${congressId}/${FormType.PAYMENT}`,
      }),
      providesTags: ["BillingField"],
    }),
    listActiveBillingFields: builder.query<FormField[], number>({
      query: (congressId) => ({
        url: `custom-field/${congressId}/${FormType.PAYMENT}/enabled/public`,
      }),
      providesTags: ["BillingField", "PublicBillingField"],
    }),
    createBillingField: builder.mutation<FormField, FormField>({
      query: (field: FormField) => ({
        url: `custom-field/`,
        method: "POST",
        data: field,
      }),
      invalidatesTags: ["BillingField", "PublicBillingField"],
    }),
    updateBillingField: builder.mutation<FormField, FormField>({
      query: (field: FormField) => ({
        url: `custom-field/${field.id}`,
        method: "PUT",
        data: field,
      }),
      invalidatesTags: ["BillingField", "PublicBillingField"],
    }),
    activateBillingField: builder.mutation<FormField, EnableRequest>({
      query: (field: EnableRequest) => ({
        url: `custom-field/${field.id}/enabled`,
        method: "PUT",
        data: field,
      }),
      invalidatesTags: ["BillingField", "PublicBillingField"],
    }),
    updateBillingFieldOrdinal: builder.mutation<FormField, UpdateOrdinal>({
      query: (field: UpdateOrdinal) => ({
        url: `custom-field/${field.id}/ordinal`,
        method: "PUT",
        data: { newOrdinal: field.newOrdinal },
      }),
      invalidatesTags: ["BillingField", "PublicBillingField"],
    }),
  }),
});

export const {
  useListAdditionalFieldsByCongressIdQuery,
  useListActiveAdditionalFieldsQuery,
  useCreateAdditionalFieldMutation,
  useUpdateAdditionalFieldMutation,
  useActivateAdditionalFieldMutation,
  useUpdateAdditionalFieldOrdinalMutation,
  useListMailingFieldsByCongressIdQuery,
  useListActiveMailingFieldsQuery,
  useCreateMailingFieldMutation,
  useUpdateMailingFieldMutation,
  useActivateMailingFieldMutation,
  useUpdateMailingFieldOrdinalMutation,
  useListBillingFieldsByCongressIdQuery,
  useListActiveBillingFieldsQuery,
  useCreateBillingFieldMutation,
  useUpdateBillingFieldMutation,
  useActivateBillingFieldMutation,
  useUpdateBillingFieldOrdinalMutation,
} = additionalFieldApi;
