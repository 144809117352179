import {
  Box,
  Container,
  HStack,
  Icon,
  Stack,
  VStack,
  Text,
  Heading,
} from "@chakra-ui/react";
import React from "react";

export interface SummaryFieldItems {
  title: string | React.ReactElement;
  text?: string;
  headingText?: string;
  icon?: any;
  full?: boolean;
}

interface SummaryFieldsProps {
  items: SummaryFieldItems[];
  iconColor?: string;
  componentKey: string;
}

export const SummaryFields: React.FC<SummaryFieldsProps> = ({
  items,
  iconColor = "green",
  componentKey,
}) => (
  <Container maxW={"6xl"} my={4} px={0}>
    <Stack
      direction={{ base: "column", md: "row" }}
      mb={"6px"}
      wrap={"wrap"}
      rowGap={8}
    >
      {items.map((item, index) => (
        <HStack
          key={componentKey + index}
          align={"top"}
          w={{ base: "98%", md: item.full ? "98%" : "49%" }}
          display={{
            base: item.text === "" && item.title === "" ? "none" : "flex",
            md: "flex",
          }}
        >
          {item.icon && (
            <Box color={iconColor + ".400"} px={2}>
              {item.icon && <Icon w={"16px"} h={"16px"} as={item.icon} />}
            </Box>
          )}
          <VStack align={"start"} flexGrow={1}>
            <Text fontWeight={600}>{item.title}</Text>
            {item.text && <Text color={"gray.600"}>{item.text}</Text>}
            {item.headingText && (
              <Heading size={"lg"} color={"gray.600"}>
                {item.headingText}
              </Heading>
            )}
          </VStack>
        </HStack>
      ))}
    </Stack>
  </Container>
);
