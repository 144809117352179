import React from "react";
import { getUserToken } from "../../utils/storage-handlers";
import { GreenButton } from "./CongressButton";
import {
  useDownloadExcelMutation,
  useDownloadExcelPresentationsMutation,
} from "../../store/participant/participant";
import { useLingui } from "@lingui/react";
import { useToast } from "@chakra-ui/react";

export enum DownloadType {
  PARTICIPANTS,
  PRESENTATIONS,
}

interface AuthenticatedLinkProps {
  url: string;
  filename: string;
  children: string | React.ReactElement | React.ReactElement[];
  congressId?: string | number;
  type?: DownloadType;
}

export const AuthenticatedDownload: React.FC<AuthenticatedLinkProps> = ({
  url,
  filename,
  children,
  congressId,
  type = DownloadType.PARTICIPANTS,
}) => {
  const { i18n } = useLingui();
  const toast = useToast();
  const link = React.useRef<HTMLAnchorElement>(null);
  const [
    downloadExcel,
    { isLoading: isDownloading, data: excelContent = null },
  ] = useDownloadExcelMutation();
  const [
    downloadExcelPresentations,
    {
      isLoading: isPresentationsDownloading,
      data: presentationsExcelContent = null,
      isError: presentationError,
    },
  ] = useDownloadExcelPresentationsMutation();

  React.useEffect(() => {
    presentationError &&
      process.env.REACT_APP_PRESENTAION_DOWNLOAD === "false" &&
      toast({
        title: "Prezentáció letöltése még nem elérhető",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
  }, [toast, presentationError]);

  const createExcelFromBlob = React.useCallback(
    async (blob: Blob) => {
      if (!link.current) {
        return;
      }
      const href = window.URL.createObjectURL(blob);

      link.current.download = filename;
      link.current.href = href;

      link.current.click();
    },
    [filename]
  );

  const handleAction = React.useCallback(async () => {
    const userToken = getUserToken();
    const result = await fetch(url, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    const blob = await result.blob();
    createExcelFromBlob(blob);
  }, [createExcelFromBlob, url]);

  const downloadClickHandler = React.useCallback(() => {
    if (congressId) {
      if (type === DownloadType.PARTICIPANTS) {
        downloadExcel(congressId);
      } else if (type === DownloadType.PRESENTATIONS) {
        downloadExcelPresentations(congressId);
      }
    } else {
      handleAction();
    }
  }, [
    congressId,
    downloadExcel,
    handleAction,
    type,
    downloadExcelPresentations,
  ]);

  React.useEffect(() => {
    if (excelContent) {
      createExcelFromBlob(excelContent);
    }
  }, [createExcelFromBlob, excelContent]);

  React.useEffect(() => {
    if (presentationsExcelContent) {
      createExcelFromBlob(presentationsExcelContent);
    }
  }, [createExcelFromBlob, presentationsExcelContent]);

  return (
    <>
      <GreenButton
        onClick={downloadClickHandler}
        isLoading={isDownloading || isPresentationsDownloading}
        loadingText={i18n._("Downloading...")}
        mt={8}
        testId={`download-${type}`}
      >
        {children}
      </GreenButton>
      <a ref={link} href={url} download={filename} style={{ display: "none" }}>
        Download
      </a>
    </>
  );
};
