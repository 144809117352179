import React from "react";
import {
  Divider,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";
import ResponsiveFormStack from "../common/ResponsiveFormStack";
import { PreviewDropdownFormControl } from "../common/FormControlComponents";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  PresentationRequest,
  emptyPresentationRequest,
} from "../../types/presentation.types";
import { PresentationForm } from "../PresentationForm";
import {
  useGetActivePresentationInfoByCongressIdQuery,
  useListActivePresentationFormByCongressIdQuery,
  useListActivePresentationTopicByCongressIdQuery,
} from "../../store/presentation/presentation";

interface PresentationsProps {
  congressId: number;
  presentations?: PresentationRequest[];
  handleChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
  setFieldValue: Function;
}

const completeArrayUntilThree = (array: (typeof data)[], data: any): void => {
  if (array.length < 3) {
    array.push(data);
    completeArrayUntilThree(array, data);
  }
};

const Presentations: React.FC<PresentationsProps> = ({
  congressId,
  presentations,
  handleChange,
  setFieldValue,
}) => {
  const { i18n } = useLingui();
  const { data: forms = [], isLoading: areFormsLoading } =
    useListActivePresentationFormByCongressIdQuery(congressId);
  const { data: topics = [], isLoading: areTopicsLoading } =
    useListActivePresentationTopicByCongressIdQuery(congressId);
  const { data: extraInfo } =
    useGetActivePresentationInfoByCongressIdQuery(congressId);
  const onPresentationChange = React.useCallback(
    (fieldName: string, value: undefined | number | string) => {
      let _presentations: PresentationRequest[] = [];
      if (value !== undefined) {
        if (presentations) {
          _presentations = [...presentations];
        }
        const _value = parseInt(value + "");
        completeArrayUntilThree(_presentations, emptyPresentationRequest);
        _presentations.splice(_value, 3 - _value);
      }
      setFieldValue("presentations", _presentations);
    },
    [presentations, setFieldValue]
  );
  const selectedPresentationNumber = React.useMemo(
    () => (presentations ? presentations.length : 0),
    [presentations]
  );
  return (
    <Stack>
      <HStack>
        <Text fontSize="xl" mb="2">
          <Trans>Presentations</Trans>
        </Text>
      </HStack>
      <ResponsiveFormStack
        firstElement={
          <PreviewDropdownFormControl
            id={"presentation"}
            label={i18n._("I keep Presentation")}
            values={[
              { id: 0, leftText: i18n._("No") },
              { id: 1, leftText: i18n._("Yes, 1 Presentation") },
              { id: 2, leftText: i18n._("Yes, 2 Presentations") },
              { id: 3, leftText: i18n._("Yes, 3 Presentations") },
            ]}
            fieldName="payment.payment"
            setFieldValue={onPresentationChange}
            selectedId={selectedPresentationNumber}
            testId="presentation-dropdown"
          />
        }
      />
      {(presentations || []).map((presentation, index) => (
        <>
          <PresentationForm
            index={index}
            presentation={presentation}
            types={forms}
            topics={topics}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            key={index}
          />
          {presentations && index !== presentations.length - 1 && (
            <Divider my={{ base: 4 }} key={"divider-" + index} />
          )}
        </>
      ))}
      {presentations &&
        presentations.length &&
        (areFormsLoading || areTopicsLoading) && (
          <>
            <Skeleton height="50px" />
            <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
            <Skeleton height="50px" />
          </>
        )}
      {extraInfo && (
        <HStack>
          <Text fontSize="m" mb="2">
            {extraInfo.additionalText}
          </Text>
        </HStack>
      )}
    </Stack>
  );
};

export default Presentations;
