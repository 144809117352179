import React from "react";
import { PresentationSummary } from "./PresentationSummary";
import { SummaryFieldItems, SummaryFields } from "./SummaryFields";
import { Trans } from "@lingui/macro";
import { Container, Divider, Heading, Stack, Text } from "@chakra-ui/react";
import { ParticipantFullSummary } from "../../types/participant.types";
import { formatDateForInput } from "../../utils/date-formatter";
import { CheckIcon } from "@chakra-ui/icons";
import { Badge } from "../preview/Badge";
import { FaRegAddressCard, FaMoneyBillWave } from "react-icons/fa";
import CurrencyContext from "../../context/CurrencyContext";
import { Currency, Language } from "../../types/conference.types";
import { FeeSummary } from "./FeeSummary";
import { AccomodationSummary } from "./AccomodationSummary";
import { ActivitySummary } from "./ActivitySummary";
import { EscortFeeSummary } from "./EscortFeeSummary";
import {
  formatAddressType,
  formatPaymentType,
  formatTitle,
} from "../../utils/type-formatter";
import { useLingui } from "@lingui/react";
import { FinalFee } from "./FinalFee";
import { NO_VALUE } from "../../utils/constants";

interface ParticipantSummaryProps {
  registrationId: number;
  participant: ParticipantFullSummary;
}

export const ParticipantSummary: React.FC<ParticipantSummaryProps> = ({
  registrationId,
  participant,
}) => {
  const { i18n } = useLingui();
  const currency = React.useMemo(() => {
    return participant.congressEvent.currency === Currency.HUF
      ? participant.congressEvent.language === Language.HUNGARIAN
        ? "Ft"
        : "HUF"
      : "EUR" || "";
  }, [participant]);

  const participantItems = React.useMemo(
    (): SummaryFieldItems[] =>
      participant
        ? (
            [
              {
                icon: CheckIcon,
                title: <Trans>Registration number</Trans>,
                text: participant.registrationNumber,
                full: true,
              },
              {
                icon: CheckIcon,
                title: <Trans>Title</Trans>,
                text: formatTitle(participant.title) || NO_VALUE,
              },
              {
                icon: CheckIcon,
                title: <Trans>Name</Trans>,
                text: participant.fullName,
              },
              {
                icon: CheckIcon,
                title: <Trans>Workplace</Trans>,
                text: participant.workplace || NO_VALUE,
              },
              {
                icon: CheckIcon,
                title: <Trans>Email</Trans>,
                text: participant.email || NO_VALUE,
              },
            ] as SummaryFieldItems[]
          ).concat(
            Object.keys(participant.customFieldValues).map((key: string) => ({
              icon: CheckIcon,
              title: key,
              text: participant.customFieldValues[key] || NO_VALUE,
            })) as SummaryFieldItems[]
          )
        : [],
    [participant]
  );

  const addressItems = React.useMemo(
    () =>
      participant && participant.address
        ? (
            [
              {
                icon: FaRegAddressCard,
                title: <Trans>Type of address</Trans>,
                text: i18n._(
                  formatAddressType(participant.address.addressType)
                ),
              },
              {
                title: "",
                text: "",
              },
              {
                icon: FaRegAddressCard,
                title: <Trans>Country</Trans>,
                text: participant.address.country || NO_VALUE,
              },
              {
                icon: FaRegAddressCard,
                title: <Trans>Postal code</Trans>,
                text: participant.address.postalCode || NO_VALUE,
              },
              {
                icon: FaRegAddressCard,
                title: <Trans>City</Trans>,
                text: participant.address.city || NO_VALUE,
              },
              {
                icon: FaRegAddressCard,
                title: <Trans>Address</Trans>,
                text: participant.address.street || NO_VALUE,
              },
              {
                icon: FaRegAddressCard,
                title: <Trans>Mobile</Trans>,
                text: participant.address.phone || NO_VALUE,
              },
            ] as SummaryFieldItems[]
          ).concat(
            Object.keys(participant.address.customFieldValues).map(
              (key: string) => ({
                icon: CheckIcon,
                title: key,
                text: participant.address.customFieldValues[key] || NO_VALUE,
              })
            ) as SummaryFieldItems[]
          )
        : [],
    [participant, i18n]
  );

  const paymentItems = React.useMemo(
    () =>
      participant && participant.payment
        ? (
            [
              {
                icon: FaMoneyBillWave,
                title: <Trans>Payment</Trans>,
                text: i18n._(formatPaymentType(participant.payment.payment)),
              },
              {
                title: "",
                text: "",
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>Billing name</Trans>,
                text: participant.payment.fullName || NO_VALUE,
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>VAT ID number</Trans>,
                text: participant.payment.taxNumber || NO_VALUE,
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>Country</Trans>,
                text: participant.payment.country || NO_VALUE,
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>Postal code</Trans>,
                text: participant.payment.postalCode || NO_VALUE,
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>City</Trans>,
                text: participant.payment.city || NO_VALUE,
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>Address</Trans>,
                text: participant.payment.street || NO_VALUE,
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>Email</Trans>,
                text: participant.payment.email || NO_VALUE,
              },
              {
                icon: FaMoneyBillWave,
                title: <Trans>Mobile</Trans>,
                text: participant.payment.phone || NO_VALUE,
              },
            ] as SummaryFieldItems[]
          ).concat(
            Object.keys(participant.payment.customFieldValues).map(
              (key: string) => ({
                icon: CheckIcon,
                title: key,
                text: participant.payment.customFieldValues[key] || NO_VALUE,
              })
            ) as SummaryFieldItems[]
          )
        : [],
    [i18n, participant]
  );

  return (
    <CurrencyContext.Provider value={currency}>
      <>
        <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
          <Heading fontSize={"3xl"}>{participant.congressEvent.name}</Heading>
          <Text color={"gray.600"} fontSize={"xl"}>
            {formatDateForInput(participant.congressEvent.startDate)} -{" "}
            {formatDateForInput(participant.congressEvent.endDate)}
          </Text>
          <Text fontSize="lg" color={"gray.500"}>
            <Trans>Your submission summary:</Trans>
          </Text>
        </Stack>
        <Badge color="green">
          <Trans>Participant information</Trans>
        </Badge>
        <SummaryFields items={participantItems} componentKey="participant" />
        <Divider />
        {participant.address &&
          participant.address.addressType &&
          participant.congressEvent.configJson.mailingDetailsEnabled && (
            <>
              <Badge color="purple">
                <Trans>Mailing address</Trans>
              </Badge>
              <SummaryFields
                items={addressItems}
                iconColor="purple"
                componentKey="address"
              />
              <Divider />
            </>
          )}
        {participant.payment && participant.payment.payment && (
          <>
            <Badge color="red">
              <Trans>Billing address</Trans>
            </Badge>
            <SummaryFields
              items={paymentItems}
              iconColor="red"
              componentKey="payment"
            />
            <Divider />
          </>
        )}
        {((participant.presentations && participant.presentations.length > 0) ||
          participant.congressEvent.configJson.presentationEnabled) && (
          <>
            <PresentationSummary
              participantId={registrationId}
              presentations={participant.presentations || []}
            />
            <Divider />
          </>
        )}
        {participant.participationFee && (
          <>
            <Badge color="red">
              <Trans>Attendance Fee</Trans>
            </Badge>
            <FeeSummary feeSummary={participant.participationFee} />
            <Divider />
          </>
        )}
        {participant.escortFee && (
          <>
            <Badge color="gray">
              <Trans>Escort Fee</Trans>
            </Badge>
            <EscortFeeSummary escortFee={participant.escortFee} />
            <Divider />
          </>
        )}
        {(participant.hotelBooking ||
          participant.congressEvent.configJson.accommodationEnabled) && (
          <>
            <Badge color="green">
              <Trans>Accomodation</Trans>
            </Badge>
            <AccomodationSummary
              accomodationSummary={participant.hotelBooking}
            />
            <Divider />
          </>
        )}
        {participant.meals && participant.meals.length > 0 && (
          <>
            <Badge color="gray">
              <Trans>Meals</Trans>
            </Badge>
            <ActivitySummary
              activitySummaries={participant.meals}
              color="gray"
            />
            <Divider />
          </>
        )}
        {participant.professionals && participant.professionals.length > 0 && (
          <>
            <Badge color="blue">
              <Trans>Professional Activities</Trans>
            </Badge>
            <ActivitySummary
              activitySummaries={participant.professionals}
              color="blue"
            />
            <Divider />
          </>
        )}
        {participant.socials && participant.socials.length > 0 && (
          <>
            <Badge color="red">
              <Trans>Social Activities</Trans>
            </Badge>
            <ActivitySummary
              activitySummaries={participant.socials}
              color="red"
            />
            <Divider />
          </>
        )}
        {participant.comment !== null && (
          <>
            <Badge color="cyan">
              <Trans>Comment</Trans>
            </Badge>
            <Text>{participant.comment}</Text>
            <Divider />
          </>
        )}
        {(participant.congressEvent.configJson.participantFeeEnabled ||
          participant.congressEvent.configJson.lunchEnabled ||
          participant.congressEvent.configJson.activityEnabled ||
          participant.congressEvent.configJson.accommodationEnabled) && (
          <>
            <Badge color="purple">
              <Trans>Total Fee</Trans>
            </Badge>
            <FinalFee totalPrice={participant.totalPrice} />
            <Divider />
          </>
        )}
      </>
    </CurrencyContext.Provider>
  );
};
