export enum PresentationType {
  FORM = "FORM",
  TOPIC = "TOPIC",
  EXTRA_INFOS = "EXTRA_INFOS",
}

export interface Presentation {
  id: number;
  name: string;
  enabled: boolean;
  ordinal: number;
  additionalText: string;
  congressId: number;
  presentationType: PresentationType;
}

export const createEmptyPresentation = (
  congressId: number,
  presentationType: PresentationType
): Presentation => ({
  id: 0,
  ordinal: 0,
  name: "",
  congressId,
  enabled: true,
  presentationType,
  additionalText: "",
});

export interface PresentationRequest {
  presentationTypeId: number;
  presentationTopicId: number;
  presentationTitle: string;
  performers: string;
  performersWorkplace: string;
}

export const emptyPresentationRequest: PresentationRequest = {
  presentationTypeId: 0,
  presentationTopicId: 0,
  presentationTitle: "",
  performers: "",
  performersWorkplace: "",
};

export interface PresentationSummary {
  participantId: number;
  presentationType: string;
  presentationTopic: string;
  presentationTitle: string;
  performers: string;
  performersWorkplace: string;
}
